import React, { useState } from 'react';
import '../../css/Roombooking.css';
import people from "../../images/New folder (4)/people.png";

function RoomBooking({ onApply }) {  
  const [showDetails, setShowDetails] = useState(false);
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);

  const calculateRooms = (totalAdults, totalChildren) => {
    let remainingAdults = totalAdults;
    let remainingChildren = totalChildren;
    let requiredRooms = 0;

    while (remainingAdults > 0 || remainingChildren > 0) {
      if (remainingAdults >= 3 && remainingChildren >= 2) {
        remainingAdults -= 3;
        remainingChildren -= 2;
      } else if (remainingAdults >= 2 && remainingChildren >= 3) {
        remainingAdults -= 2;
        remainingChildren -= 3;
      } else if (remainingAdults > 0) {
        remainingAdults -= Math.min(remainingAdults, 3);
      } else if (remainingChildren > 0) {
        remainingChildren -= Math.min(remainingChildren, 2);
      }
      requiredRooms++;
    }

    return requiredRooms;
  };

  const handleAdultChange = (change) => {
    const newAdults = Math.max(0, adults + change);
    setAdults(newAdults);
    setRooms(calculateRooms(newAdults, children));
  };

  const handleChildChange = (change) => {
    const newChildren = Math.max(0, children + change);
    setChildren(newChildren);
    setRooms(calculateRooms(adults, newChildren));
  };

  const resetForm = () => {
    setAdults(0);
    setChildren(0);
    setRooms(1);
    setShowDetails(false);
  };

  const applyChanges = () => {
    onApply(adults + children, adults, children, rooms);
    alert(`Adults: ${adults}, Children: ${children}, Rooms: ${rooms}`);
  };

  return (
    <div>
      <div className="roombooking-main-div" onClick={() => setShowDetails(!showDetails)}>
        <h1 className='roombooking-h1'>Room Booking</h1>
        <img src={people} alt="people" className="roomboooking-icon" />
        <p className="roombooking-p1">Guests and Rooms</p>
      </div>

      {showDetails && (
        <div className="roombooking-details">
          <div className="roombooking-row">
            <span>Adults</span>
            <button onClick={() => handleAdultChange(-1)} disabled={adults === 0}>-</button>
            <span>{adults}</span>
            <button onClick={() => handleAdultChange(1)}>+</button>
          </div>
          
          <div className="roombooking-row">
            <span>Children</span>
            <button onClick={() => handleChildChange(-1)} disabled={children === 0}>-</button>
            <span>{children}</span>
            <button onClick={() => handleChildChange(1)}>+</button>
          </div>

          <div className="roombooking-row">
            <span>Rooms</span>
            <span>{rooms}</span>
          </div>

          <div className="roombooking-buttons">
            <button onClick={resetForm}>Reset</button>
            <button onClick={applyChanges}>Apply</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default RoomBooking;

import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/createaccountform.css';
import logo02 from '../images/New folder (4)/logo/LOGO PNG FORMAT WITH TAGLINE (1).png';
import axios from "axios";
import { validateName, validatePassword, validatePhoneNumber } from "../validation";
import { BASE_URL } from "../constants/constant";

const CreateAccountForm = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    passwordconformed: "",
    alternativePhone: "",
    phonenumber: "",
  });
  
  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    passwordconformed: "",
    phonenumber: "",
    alternativePhone: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(prev => !prev);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(prev => !prev);

  const navigate = useNavigate();

  const formdata = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateFields = () => {
    let formErrors = {};
    let isValid = true;

    const { firstname, lastname, email, password, passwordconformed, phonenumber, alternativePhone } = formData;

    if (!firstname || !lastname || !email || !password || !passwordconformed || !phonenumber) {
      isValid = false;
      formErrors.general = "Please fill in all required fields.";
    }

    if (!validateName(firstname) || !validateName(lastname)) {
      isValid = false;
      formErrors.firstname = "Name should only contain letters.";
      formErrors.lastname = "Name should only contain letters.";
    }

    if (!validatePassword(password)) {
      isValid = false;
      formErrors.password = "Password must be 8+ chars with a special character.";
    }

    if (password !== passwordconformed) {
      isValid = false;
      formErrors.passwordconformed = "Passwords do not match.";
    }

    if (!validatePhoneNumber(phonenumber) || (alternativePhone && !validatePhoneNumber(alternativePhone))) {
      isValid = false;
      formErrors.phonenumber = "Phone number must be 10 digits.";
      formErrors.alternativePhone = "Phone number must be 10 digits.";
    }

    setErrors(formErrors);
    return isValid;
  };

  const register = (e) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }

    const { firstname, lastname, email, password, phonenumber, alternativePhone } = formData;

    const datas = {
      firstname,
      lastname,
      email,
      password,
      phonenumber,
      alternativePhone,
    };

    axios.post(`${BASE_URL}create_user.php`, datas, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response => {
      if (response.data.error && response.data.error.includes("Email ID already exists")) {
        alert("Email already exists. Please use a different email address.");
      } else {
        alert(response.data.user_id || "Account created successfully!");
        sessionStorage.setItem("user_id",response.data.user_id)
        // Redirect to the login page after account creation
        navigate("/createaccount_submit");
      }
    }).catch(error => {
      console.error(error);
      alert("Failed to create account. Please try again.");
    });
  };

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="container p-4 rounded shadow-lg col-md-4 col-lg-4 createaccount-formdiv">
        <div className="text-center mb-3">
          <img src={logo02} alt="Logo" className="img-fluid" style={{ maxWidth: "200px" }} />
        </div>

        <h2 className="text-center mb-4 createaccount-form-mainheader">Create Account</h2>

        <form className="createaccountform">
          <div className="row mb-3">
            <div className="col">
              <input type="text" name="firstname" className="form-control" onChange={formdata} placeholder="First Name" />
              {errors.firstname && <small className="text-danger">{errors.firstname}</small>}
            </div>
            <div className="col">
              <input type="text" name="lastname" className="form-control" onChange={formdata} placeholder="Last Name" />
              {errors.lastname && <small className="text-danger">{errors.lastname}</small>}
            </div>
          </div>

          <div className="mb-3">
            <input type="email" name="email" className="form-control" onChange={formdata} placeholder="Enter Email" />
            {errors.email && <small className="text-danger">{errors.email}</small>}
          </div>

          <div className="row mb-3">
            <div className="col position-relative">
              <input type={showPassword ? "text" : "password"} name="password" className="form-control" onChange={formdata} placeholder="Password" autoComplete="new-password" />
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={togglePasswordVisibility} className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer" style={{ cursor: "pointer" }} />
              {errors.password && <small className="text-danger">{errors.password}</small>}
            </div>
            <div className="col position-relative">
              <input type={showConfirmPassword ? "text" : "password"} name="passwordconformed" className="form-control" onChange={formdata} placeholder="Confirm Password" autoComplete="new-password" />
              <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} onClick={toggleConfirmPasswordVisibility} className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer" style={{ cursor: "pointer" }} />
              {errors.passwordconformed && <small className="text-danger">{errors.passwordconformed}</small>}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col">
              <input type="text" name="phonenumber" className="form-control" onChange={formdata} placeholder="Phone Number" />
              {errors.phonenumber && <small className="text-danger">{errors.phonenumber}</small>}
            </div>
            <div className="col">
              <input type="text" name="alternativePhone" className="form-control" onChange={formdata} placeholder="Alternative Number" />
              {errors.alternativePhone && <small className="text-danger">{errors.alternativePhone}</small>}
            </div>
          </div>

          <button type="button" onClick={register} className="row btn btn-primary mb-3 w-100">Create Account</button>
        </form>

        <h4 className="text-center">Or Sign Up With</h4>
        <div className="d-flex justify-content-center my-3">
          <FontAwesomeIcon icon={faFacebook} className="text-primary fs-3 mx-2" />
          <FontAwesomeIcon icon={faGoogle} className="text-danger fs-3 mx-2" />
        </div>

        <p className="createaccount-form-terms_and_service">
          By creating an account you agree to <b>LETS ENJOY Terms of Services</b> and <b>Privacy Policy</b>.
        </p>
        <p className="text-white text-center">
          Have an account? <NavLink to="/loginpage" className="createaccount-form-login-button">Login</NavLink>
        </p>
      </div>
    </div>
  );
};

export default CreateAccountForm;

import React from "react";
import './css/CreateAccount.css';
import CreateAccountForm from "./Forms/Createaccountform";
import logo02 from '../src/images/New folder (4)/logo/LOGO PNG FORMAT WITH TAGLINE (1).png';


function CreateAccount() {
    return (
        
        <div id="CreateAccount">
            
           
             
            <CreateAccountForm className="CreateAccountForm-compoment" />
           
           

        </div>
    );
}

export default CreateAccount;







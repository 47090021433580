import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaYoutube } from 'react-icons/fa';
import '../../css/Testimonial.css';

import img1 from '../../images/New folder (4)/tour_images/(1).jpg';
import img2 from '../../images/New folder (4)/tour_images/(2).jpg';
import img3 from '../../images/New folder (4)/tour_images/(3).jpg';
import img4 from '../../images/New folder (4)/tour_images/(4).jpg';
import img5 from '../../images/New folder (4)/tour_images/(5).jpg';
import img6 from '../../images/New folder (4)/tour_images/(6).jpg';
import img7 from '../../images/New folder (4)/tour_images/(7).jpg';
import img8 from '../../images/New folder (4)/tour_images/(8).jpg';
import img9 from '../../images/New folder (4)/tour_images/(9).jpg';
import img10 from '../../images/New folder (4)/tour_images/(10).jpg';
import img11 from '../../images/New folder (4)/tour_images/(11).jpg';
import img13 from '../../images/New folder (4)/tour_images/(13).jpg';
import img14 from '../../images/New folder (4)/tour_images/(14).jpg';
import img15 from '../../images/New folder (4)/tour_images/(15).jpg';
import img16 from '../../images/New folder (4)/tour_images/(16).jpg';
import img17 from '../../images/New folder (4)/tour_images/(17).jpg';
import img18 from '../../images/New folder (4)/tour_images/(18).jpg';
import img19 from '../../images/New folder (4)/tour_images/(19).jpg';
import img20 from '../../images/New folder (4)/tour_images/(20).jpg';
import img22 from '../../images/New folder (4)/tour_images/(22).jpg';
import img23 from '../../images/New folder (4)/tour_images/(23).jpg';
import img24 from '../../images/New folder (4)/tour_images/(24).jpg';
import img25 from '../../images/New folder (4)/tour_images/(25).jpg';

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img13, img14, img15, img16, img17, img18, img19, img20, img22, img23, img24, img25];

function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="testimonial-maindiv position-relative">
      <h1 className="testimonial-head1">Testimonials</h1>

      <a href="https://youtu.be/xxrR-IXI-0o?si=IvreMiDd042pExLf" target="_blank" rel="noopener noreferrer ">
        <button className="btn testimonial-button">
          <FaYoutube  /> What Our Customers Are Saying
        </button>
      </a>

      <div className="image-grid">
        {[0, 1, 2, 3, 4, 5].map((index) => (
          <div key={index} className="image-wrapper">
            <img src={images[(currentIndex + index) % images.length]} alt={`Slide ${index + 1}`} className="img-fluid slide-in" />
          </div>
        ))}
      </div>

      <div className="text-overlay position-absolute top-50 start-0 translate-middle-y text-white px-4 px-md-5">
      </div>
    </div>
  );
}

export default Testimonials;

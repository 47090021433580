import React, { useEffect, useState } from "react";
import "../css/Userdetailsform.css";
import { NavLink, useNavigate } from "react-router-dom";
import logo02 from "../images/New folder (4)/logo/LOGO PNG FORMAT WITH TAGLINE (1).png";
import axios from "axios";
import { BASE_URL } from "../constants/constant";

function UserDetailsForm() {
  const [countriesData, setCountriesData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [states, setStates] = useState([]);
  const [formData, setFormData] = useState({
    address1: "",
    address2: "",
    dob: "",
    gender: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    userid: sessionStorage.getItem("user_id"),
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();



  // Fetch all countries on component mount
  useEffect(() => {
    axios
      .get(`${BASE_URL}fetchcountry.php`)
      .then((response) => {
        const data = response.data.data;
        setCountriesData(data);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  const handleCountryChange = (event) => {
    const countryId = event.target.value;
    setSelectedCountry(countryId);

    // Fetch states based on selected country
    axios
      .get(`${BASE_URL}fetchstates.php`, {
        params: { country_id: countryId },
      })
      .then((response) => {
        const data = response.data.data;
        setStates(data ? data.map((state) => state.state_name) : []);
        setFormData({ ...formData, country: countryId });
      })
      .catch((error) => {
        console.error("Error fetching states:", error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateFields = () => {
    let formErrors = {};
    let isValid = true;

    const { address1, address2, dob, gender, country, state, city, pincode } = formData;

    if (!address1 || !address2 || !dob || !gender || !country || !state || !city || !pincode) {
      isValid = false;
      formErrors.general = "Please fill in all required fields.";
    }

    setErrors(formErrors);
    return isValid;
  };

  const register = (e) => {
    e.preventDefault(); // Prevent form default submission behavior

    if (!validateFields()) {
      return;
    }

    const userDetails = { ...formData };

    axios
      .post(`${BASE_URL}user_details.php`, userDetails, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.message) {
          alert("User details saved successfully!");
          navigate("/UserDetailsform");
        } else {
          alert(response.data.error || "Failed to save user details. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Error submitting details. Please try again.");
      });
  };

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="p-5 rounded shadow-lg userdetailsform-container">
      
       

        <form>
        <img
            src={logo02}
            alt="Logo"
            className="img-fluid userdetailsform-logo"
            style={{ maxWidth: "200px" }}
          />
           <h2 className="text-center mb-5 userdetailsform-h2 ">Enter Personal Details</h2>
          <div className="mb-4">
            <input
              type="text"
              className="form-control"
              name="address1"
              onChange={handleInputChange}
              placeholder="Enter Address 1"
            />
          </div>

          <div className="mb-4">
            <input
              type="text"
              className="form-control"
              name="address2"
              onChange={handleInputChange}
              placeholder="Enter Address 2"
            />
          </div>

          <div className="row mb-4">
            <div className="col-md-6">
              <input
                type="date"
                className="form-control"
                name="dob"
                onChange={handleInputChange}
                placeholder="DOB"
              />
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                name="gender"
                onChange={handleInputChange}
                placeholder="Gender"
              />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-6">
              <select
                className="form-select"
                name="country"
                value={selectedCountry}
                onChange={handleCountryChange}
              >
                <option value="" disabled>Select Country</option>
                {countriesData.map((country, index) => (
                  <option key={index} value={country.id}>
                    {country.country_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-6">
              <select
                className="form-select"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                disabled={!states.length}
              >
                <option value="" disabled>
                  {states.length ? "Select State" : "No states available"}
                </option>
                {states.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                name="city"
                onChange={handleInputChange}
                placeholder="City"
              />
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                name="pincode"
                onChange={handleInputChange}
                placeholder="Pincode"
              />
            </div>
          </div>

          <button
            type="button"
            onClick={register}
            className="btn userdetailsform-submitbutton"
          >
            Create Account
          </button>
        </form>

        <div className="text-center mt-4">
          <p>
            Have an Account?{" "}
            <NavLink to="/loginpage" className="text-decoration-none text-dark">
              <b>Login</b>
            </NavLink>
          </p>
        </div>
      </div>
    </div>
  );
}

export default UserDetailsForm;

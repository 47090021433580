import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/App.css';
import CreateAccount from './CreateAccount';
import UserDetails from './Forms/UserDetails';
import Login from './Forms/Login';
import App from './App';
import Homepage from './Navbar/Pages/Homepage';

// import Navbar from './Navbar/Navbar';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  

<React.StrictMode>
<App />
</React.StrictMode>
);


import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // For navigation
import TopBar from "../Navbar/Pages/Topbar";
import Sidebar from "../sidebar/sidebar";
import axios from "axios";
import { BASE_URL_ADMIN } from "../constants/constant";
import { validateImageFile, validateOnlyNumbers } from "../validation";
import { Toast, ToastContainer } from "react-bootstrap";

function Familypackageadding() {
    const [formData, setFormData] = useState({
        packagename: "",
        destination: "",
        perheadprice: "",
        advancepyment: "",
        roomamount: "",
        child_perheadprice: "",
        packageimage: null,
        vehicleDetails: [], // Array to hold vehicle details objects
    });

    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
    const [adminId, setAdminId] = useState(null);

    // Toast state
    const [toast, setToast] = useState({ show: false, message: "", variant: "" });

    const navigate = useNavigate(); // Navigation hook

    useEffect(() => {
        const storedAdminId = sessionStorage.getItem("admin_id");
        if (!storedAdminId) {
            navigate("/adminlogin"); // Redirect to login page if admin_id is not found
        } else {
            setAdminId(storedAdminId);
        }
    }, [navigate]);

    const handleSidebarToggle = (isOpen) => setIsSidebarOpen(isOpen);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && !validateImageFile(file)) {
            setToast({ show: true, message: "Please upload a valid image file (JPEG, JPG, PNG, GIF).", variant: "warning" });
            e.target.value = "";
            return;
        }
        setFormData((prevData) => ({ ...prevData, packageimage: file }));
    };

    const handleVehicleDetailsChange = (index, field, value) => {
        const updatedVehicleDetails = [...formData.vehicleDetails];
        updatedVehicleDetails[index][field] = value;
        setFormData((prevData) => ({ ...prevData, vehicleDetails: updatedVehicleDetails }));
    };

    const addVehicleDetail = () => {
        setFormData((prevData) => ({
            ...prevData,
            vehicleDetails: [...prevData.vehicleDetails, { vehicleName: "", advancePrice: "", seatingCapacity: "" }],
        }));
    };

    const removeVehicleDetail = (index) => {
        const updatedVehicleDetails = formData.vehicleDetails.filter((_, i) => i !== index);
        setFormData((prevData) => ({ ...prevData, vehicleDetails: updatedVehicleDetails }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "perheadprice" && !validateOnlyNumbers(value)) {
            setToast({ show: true, message: "Please enter a valid numeric value for total price!", variant: "warning" });
            return;
        } else if (name === "advancepyment" && !validateOnlyNumbers(value)) {
            setToast({ show: true, message: "Please enter a valid numeric value!", variant: "warning" });
            return;
        } else if (name === "roomamount" && !validateOnlyNumbers(value)) {
            setToast({ show: true, message: "Please enter a valid numeric value!", variant: "warning" });
            return;
        } else if (name === "child_perheadprice" && !validateOnlyNumbers(value)) {
            setToast({ show: true, message: "Please enter a valid numeric value!", variant: "warning" });
            return;
        }
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!adminId) {
            setToast({ show: true, message: "Admin ID is required to submit the form!", variant: "danger" });
            return;
        }

        // Prepare form data for submission
        const formDataToSend = new FormData();
        formDataToSend.append("packagename", formData.packagename);
        formDataToSend.append("destination", formData.destination);
        formDataToSend.append("perheadprice", formData.perheadprice);
        formDataToSend.append("advancepyment", formData.advancepyment);
        formDataToSend.append("roomamount", formData.roomamount);
        formDataToSend.append("child_perheadprice", formData.child_perheadprice);
        if (formData.packageimage) {
            formDataToSend.append("packageimage", formData.packageimage);
        }
        formDataToSend.append("adminId", adminId);

        // Add vehicle details to form data
        formData.vehicleDetails.forEach((vehicle, index) => {
            formDataToSend.append(`vehicleDetails[${index}][vehicleName]`, vehicle.vehicleName);
            formDataToSend.append(`vehicleDetails[${index}][advancePrice]`, vehicle.advancePrice);
            formDataToSend.append(`vehicleDetails[${index}][seatingCapacity]`, vehicle.seatingCapacity);
        });

        // Send data using axios
        axios
            .post(`${BASE_URL_ADMIN}addingfamilypackagevehicle.php`, formDataToSend, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((response) => {
                if (response.data.message) {
                    setToast({ show: true, message: "Family package vehicle added successfully!", variant: "success" });
                    setFormData({
                        packagename: "",
                        destination: "",
                        perheadprice: "",
                        advancepyment: "",
                        roomamount: "",
                        child_perheadprice: "",
                        packageimage: null,
                        vehicleDetails: [],
                    });
                } else {
                    setToast({ show: true, message: `Error: ${response.data.error}`, variant: "danger" });
                }
            })
            .catch((error) => {
                console.error("Error adding family package vehicle:", error);
                setToast({ show: true, message: "An error occurred while adding the family package vehicle.", variant: "danger" });
            });
    };

    return (
        <div className="container-fluid">
            <TopBar />
            <div className="row">
                <Sidebar onToggle={handleSidebarToggle} />
                <div className={`col ${!isSidebarOpen ? "col-12" : "col-md-9 offset-md-3"} p-4`}>
                    <h2 className="text-center mb-4">Add Family Package Vehicle</h2>
                    <div className="d-flex justify-content-center">
                        <form
                            onSubmit={handleSubmit}
                            style={{ width: "100%", maxWidth: "700px" }}
                            className="p-4 border rounded shadow"
                        >
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <label htmlFor="packagename" className="form-label">
                                        Package Name
                                    </label>
                                    <input
                                        type="text"
                                        id="packagename"
                                        name="packagename"
                                        className="form-control"
                                        value={formData.packagename}
                                        onChange={handleChange}
                                        placeholder="Enter package name"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="destination" className="form-label">
                                        Destination
                                    </label>
                                    <input
                                        type="text"
                                        id="destination"
                                        name="destination"
                                        className="form-control"
                                        value={formData.destination}
                                        onChange={handleChange}
                                        placeholder="Enter destination name"
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <label htmlFor="perheadprice" className="form-label">
                                        Adult Per-Head Price
                                    </label>
                                    <input
                                        type="text"
                                        id="perheadprice"
                                        name="perheadprice"
                                        className="form-control"
                                        value={formData.perheadprice}
                                        onChange={handleChange}
                                        placeholder="Enter per-head price"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="child_perheadprice" className="form-label">
                                        Child Per-Head Price
                                    </label>
                                    <input
                                        type="text"
                                        id="child_perheadprice"
                                        name="child_perheadprice"
                                        className="form-control"
                                        value={formData.child_perheadprice}
                                        onChange={handleChange}
                                        placeholder="Enter per-head price for children"
                                    />
                                </div>
                               
                            </div>


                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <label htmlFor="advancepaymentpercentage" className="form-label">
                                        Advance Payment Percentage
                                    </label>
                                    <input
                                        type="text"
                                        id="advancepyment"
                                        name="advancepyment"
                                        className="form-control"
                                        value={formData.advancepyment}
                                        onChange={handleChange}
                                        placeholder="Enter advance paymment percentage"
                                    />
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="advancepaymentpercentage" className="form-label">
                                        Room Amount
                                    </label>
                                    <input
                                        type="text"
                                        id="roomamount"
                                        name="roomamount"
                                        className="form-control"
                                        value={formData.roomamount}
                                        onChange={handleChange}
                                        placeholder="Enter Room Amount "
                                    />
                                    
                                </div>
                                </div>
                                <div className="row mb-3">
                                <div className="col-md-6">
                                    <label htmlFor="packageimage" className="form-label">
                                        Upload Image
                                    </label>
                                    <input
                                        type="file"
                                        id="packageimage"
                                        name="packageimage"
                                        className="form-control"
                                        onChange={handleFileChange}
                                    />
                                </div>
                             
                            </div>

                            <div className="mb-3">
                                {formData.vehicleDetails.map((vehicle, index) => (
                                    <div key={index} className="mb-3">
                                        <input
                                            type="text"
                                            className="form-control mb-2"
                                            value={vehicle.vehicleName}
                                            onChange={(e) =>
                                                handleVehicleDetailsChange(index, "vehicleName", e.target.value)
                                            }
                                            placeholder="Vehicle Name"
                                        />
                                        <input
                                            type="text"
                                            className="form-control mb-2"
                                            value={vehicle.advancePrice}
                                            onChange={(e) =>
                                                handleVehicleDetailsChange(index, "advancePrice", e.target.value)
                                            }
                                            placeholder="Vehicle Price"
                                        />
                                        <input
                                            type="text"
                                            className="form-control mb-2"
                                            value={vehicle.seatingCapacity}
                                            onChange={(e) =>
                                                handleVehicleDetailsChange(index, "seatingCapacity", e.target.value)
                                            }
                                            placeholder="Seating Capacity"
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => removeVehicleDetail(index)}
                                        >
                                            &times; Remove
                                        </button>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={addVehicleDetail}
                                >
                                    + Add Vehicle Details
                                </button>
                            </div>

                            <button type="submit" className="btn btn-primary w-100">
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            {/* Toast for Notifications */}
            <ToastContainer position="top-center" className="p-3">
                <Toast
                    onClose={() => setToast((prev) => ({ ...prev, show: false }))}
                    show={toast.show}
                    delay={3000}
                    autohide
                    bg={toast.variant}
                >
                    <Toast.Body>{toast.message}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
}

export default Familypackageadding;

import { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import logo from "../../images/New folder (4)/logo/logo01.png";
import AvatarImg from "../../images/New folder (4)/admin-avatar.webp";
import { Link } from "react-router-dom";
import '../../css/topbar.css';

function TopBar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState([false]);
  const navigate = useNavigate(); // Initialize navigate hook

  const toggleDropdown = (index) => {
    setIsDropdownOpen((prev) => {
      const newDropdownState = [...prev];
      newDropdownState[index] = !newDropdownState[index];
      return newDropdownState;
    });
  };

  const handleLogout = () => {
    sessionStorage.clear(); // Clear all session storage
    navigate("/adminlogin"); // Redirect to admin login page
  };

  return (
    <div className="topbar">
      <div className="topbar-content">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <div className="marquee-container">
          <marquee behavior="scroll" direction="left">
            📢 Important Notification 1 | 🌟 New Feature Launched! | 🎉 Welcome to Our Platform! | 🚀 Stay Tuned for Updates!
          </marquee>
        </div>
        <div className="account-container">
          <img src={AvatarImg} alt="Avatar" className="avatar" />
          <button
            onClick={() => toggleDropdown(0)}
            className={`dropdown-toggle ${isDropdownOpen[0] ? "open" : ""}`}
          >
            <div className="account-details">
              <a>Username</a>
              <p>Admin</p>
            </div>
          </button>
          <ul className={`dropdown ${isDropdownOpen[0] ? "open" : ""}`}>
            <li>
              <Link to="/profile">My Profile</Link>
            </li>
            <li>
              <button onClick={handleLogout} className="logout-button">
                Log Out
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TopBar;

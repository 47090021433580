import jsPDF from "jspdf";
import "jspdf-autotable";
import logo02 from '../../images/New folder (4)/logo/logo01.png'; // Ensure the logo path is correct

export const Pdfgenerator_tourpackage = (booking) => {
  const {
    package_name,
    total_amount,
    advance_payment,
    balance_payment,
    trip_start_date,
    destination,
    vehicle_name,
    seating_capacity,
    adults,
    children,
    rooms,
  } = booking;

  const doc = new jsPDF();

  // Format the departure time in dd-mm-yyyy and hh:mm:ss format
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
  
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
  
    const ampm = hours >= 12 ? "PM" : "AM"; // Determine AM or PM
    hours = hours % 12 || 12; // Convert to 12-hour format (0 becomes 12)
  
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  };

  // Add a border to create a bill-like feel
  doc.rect(10, 10, 190, 277); // Rect(x, y, width, height)

  // Add the logo to the left of the heading, fit the image into the box
  const logoWidth = 30;
  const logoHeight = 30;
  const img = new Image();
  img.src = logo02;

  img.onload = function() {
    // Calculate aspect ratio
    const aspectRatio = img.width / img.height;
    const newWidth = logoWidth;
    const newHeight = newWidth / aspectRatio; // Maintain aspect ratio

    // Add image to the left of the heading
    doc.addImage(logo02, 'PNG', 15, 10, newWidth, newHeight); // Parameters: image, type, x, y, width, height

    // Add header section with styling, move the text to the right of the logo
    doc.setFontSize(22);
    doc.setFont("helvetica", "bold");
    doc.text(`${package_name} - Ticket`, 70, 20); // Start the text at x = 50 to avoid overlapping with logo

    // Add a line separator under the title
    doc.setLineWidth(0.5);
    doc.line(10, 25, 200, 25); // Line(x1, y1, x2, y2)

    // Add additional header information
    doc.setFontSize(12);
    doc.setFont("helvetica", "italic");
    doc.text("Thank you for booking with us!", 105, 30, { align: "center" });

    // Add booking details in a well-structured table format
    const rupeeSymbol = "Rs."; // You can use ₹ for the symbol
    const ticketDetails = [
      ["Package Name", package_name],
      ["Trip Start Date", formatDateTime(trip_start_date)],
      ["Destination", destination],
      ["Vehicle", `${vehicle_name} (Seats: ${seating_capacity})`],
      ["Adults", adults],
      ["Children", children],
      ["Rooms", rooms],
      ["Total Amount", `${rupeeSymbol} ${total_amount.toFixed(2)}`],
      ["Advance Payment", `${rupeeSymbol} ${advance_payment.toFixed(2)}`],
      ["Balance Payment", `${rupeeSymbol} ${balance_payment.toFixed(2)}`],
    ];

    // Add the table using autoTable
    doc.autoTable({
      startY: 40,
      headStyles: {
        fillColor: [0, 112, 192], // Blue color for table header
        textColor: [255, 255, 255], // White text in header
        fontStyle: "bold",
      },
      bodyStyles: {
        fillColor: [242, 242, 242], // Light grey background for rows
        textColor: [0, 0, 0], // Black text
      },
      margin: { left: 15, right: 15 }, // Margins
      body: ticketDetails,
      theme: "grid",
    });

    // Save the PDF
    doc.save(`Ticket_${package_name}.pdf`);
  };
};

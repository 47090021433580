import React, { useEffect, useState } from 'react';
import '../../css/Packages.css';
import { FaLocationArrow } from 'react-icons/fa';
import { NavLink } from "react-router-dom";
import axios from 'axios';
import { BASE_URL } from "../../constants/constant";

function Packages() {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    // Fetch package data from PHP API
    axios.get(`${BASE_URL}viewpackage_user.php`)
      .then(response => {
        if (response.data.success) {
          setPackages(response.data.data); // Set the packages state with the fetched data
          
        } else {
          console.error("Failed to fetch packagesm:", response.data.message);
        }
      })
      .catch(error => {
        console.error("Error fetching packages:", error);
      });
  }, []);

  // Helper function to format date and time
  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    const formattedDate = date.toLocaleDateString("en-GB", {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    const formattedTime = date.toLocaleTimeString("en-GB", {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });

    return `${formattedDate} at ${formattedTime}`;
  };

  return (
    <div className="packages-maindiv">
      <h2 className="packages-h2"><b>Tour Packages</b></h2>

      <div className="packages-section">
        <div className="packages-list">
          {packages.length > 0 ? (
            packages.map((pkg, index) => (
              <div className="packages" key={index}>
                {/* Use the full image URL returned from the API */}
                <img src={pkg.package_image} alt={pkg.Packagename} className="imgs" />
                <p className="packages-name">{pkg.Packagename}</p>
                <p className='package-days'>
                  Departure: {formatDateTime(pkg.departure_time)}
                </p>
                <p className='package-days'>
                  Arrival: {formatDateTime(pkg.arrival_time)}
                </p>
                <p className='package-location'>
                  <FaLocationArrow />
                  {pkg.source} to {pkg.destination}
                </p>
                
                {/* Conditional NavLink based on bustype_id */}
                {pkg.bustype_id === "1" ? (
                  <NavLink to="/seatbooking" state={{ packageId: pkg.id, bustype: pkg.bustype_id, seatprice: pkg.advanve_payment, packageprice:pkg.package_price }} className="packages-navlink">View Package</NavLink>
               
                ) : pkg.bustype_id === "2" ? ( 
                  <NavLink to="/seatbooking2" state={{ packageId: pkg.id,bustype:pkg.bustype_id,seatprice:pkg.advanve_payment }} className="packages-navlink">View Package</NavLink>
                ) : (
                  <p>No booking available for this package</p> // Optional: message for other bustype_id values
                )}
              </div>
            ))
          ) : (
            <p>No packages available</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Packages;

import React from "react";
import Navbar from "../Navbar";

import Tourpackage_tickets from "./Tourpackage_tickets";
import Familypackage from "./Familypackage";
import Familypackage_tickets from "./Familypackage_tickets";

const Userprofile = () => {
    return (
        <div>
            <Navbar />
           <Tourpackage_tickets/>

           <Familypackage_tickets/>
        </div>
    );
};

export default Userprofile;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Contact from "../Navbar/Pages/Contact";
import "../css/familypackagebilling.css";
import { BASE_URL } from "../../src/constants/constant";

const Familypackagebilling = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Extract state passed via location
    const {
        totalPackagePrice = 0,
        advancePayment = 0,
        packageId = null,
        adults = 0,
        vehicleId = null,
        vehicleName=0,
        children = 0,
        rooms = 0,
        roomrent = 0,
        perheadprice = 0,
        childrenPerHeadPrice = 0,
        tripStartDate = "",
    } = location.state || {};

    const userId = sessionStorage.getItem("user_id");

    const [isLocked, setIsLocked] = useState(false);

    // Validate and set lock state for the payment button
    useEffect(() => {
        setIsLocked(
            !userId ||
                totalPackagePrice <= 0 ||
                advancePayment <= 0 ||
                !packageId ||
                roomrent <= 0 ||
                perheadprice <= 0 ||
                adults <= 0 ||
                rooms <= 0 ||
                !vehicleId
        );
    }, [totalPackagePrice, advancePayment, packageId, userId]);

    // Format date to display as "DD/MM/YYYY"
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) return "Invalid Date";
        return date.toLocaleDateString("en-GB");
    };

    // Handle booking request
    const handleBooking = async () => {
        const bookingDetails = {
            user_id: userId,
            package_id: packageId,
            payment_status: "booked",
            balance_payment: "pending",
            
            adults,
            children,
            rooms,
            vehicleId,
            tripStartDate: new Date(tripStartDate).toISOString().split("T")[0], // Ensure date format is YYYY-MM-DD
        };

        console.log("Booking Details:", bookingDetails); // Debugging info

        try {
            const response = await fetch(`${BASE_URL}familypackagebooking.php`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(bookingDetails),
            });

            const result = await response.json();

            if (result.success) {
                alert("Booking successful!");
                navigate("/loginsubmit");
            } else {
                console.error("Error:", result.message);
                alert(result.message || "Booking failed. Please try again.");
            }
        } catch (error) {
            console.error("Error:", error);
            alert("Something went wrong. Please try again.");
        }
    };

    return (
        <div>
            <Navbar />
            <div className="familypackagebilling-container">
                <h2 className="familypackage_billing-h2">Billing Details</h2>
                <div className="familypackage_billing-details">
                    {/* Display billing details */}
                    <div className="familypackage_billing-row">
                        <span className="familypackage_billing-label">Start Date of the Trip:</span>
                        <span className="familypackage_billing-value">{formatDate(tripStartDate)}</span>
                    </div>
                    <div className="familypackage_billing-row">
                        <span className="familypackage_billing-label">Adult Per Head Price:</span>
                        <span className="familypackage_billing-value">Rs. {perheadprice}</span>
                    </div>
                    <div className="familypackage_billing-row">
                        <span className="familypackage_billing-label">Child Per Head Price:</span>
                        <span className="familypackage_billing-value">Rs. {childrenPerHeadPrice}</span>
                    </div>
                    <div className="familypackage_billing-row">
                        <span className="familypackage_billing-label">Room Rent:</span>
                        <span className="familypackage_billing-value">Rs. {roomrent}</span>
                    </div>
                    <div className="familypackage_billing-row">
                        <span className="familypackage_billing-label">Adults:</span>
                        <span className="familypackage_billing-value">{adults}</span>
                    </div>
                    <div className="familypackage_billing-row">
                        <span className="familypackage_billing-label">Children:</span>
                        <span className="familypackage_billing-value">{children}</span>
                    </div>
                    <div className="familypackage_billing-row">
                        <span className="familypackage_billing-label">No of Rooms:</span>
                        <span className="familypackage_billing-value">{rooms}</span>
                    </div>
                    <div className="familypackage_billing-row">
                        <span className="familypackage_billing-label">Vehicle:</span>
                        <span className="familypackage_billing-value">{vehicleName}</span>
                    </div>
                    <div className="familypackage_billing-row">
                        <span className="familypackage_billing-label">Total Package Price:</span>
                        <span className="familypackage_billing-value">Rs. {totalPackagePrice}</span>
                    </div>
                    <div className="familypackage_billing-row">
                        <span className="familypackage_billing-label">Advance Payment:</span>
                        <span className="familypackage_billing-value">Rs. {advancePayment}</span>
                    </div>
                    <div className="familypackage_billing-row">
                        <span className="familypackage_billing-label">Balance Payment:</span>
                        <span className="familypackage_billing-value">Rs. {totalPackagePrice - advancePayment}</span>
                    </div>

                    {/* Payment Button */}
                    <button
                        className="make-payment-button"
                        onClick={handleBooking}
                        disabled={isLocked}
                    >
                        Make Payment Rs.{advancePayment}
                    </button>
                </div>
            </div>
            <Contact />
        </div>
    );
};

export default Familypackagebilling;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "./Topbar";
import Sidebar from "../../sidebar/sidebar";
import "../../css/admindashboard.css"; // Updated CSS for styling
import { BASE_URL_ADMIN } from "../../constants/constant";
import axios from "axios";

const Adminviewfamilypackage_booking = () => {
  const [adminId, setAdminId] = useState(null);
  const [packages, setPackages] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(true); // Track sidebar open/closed state
  const navigate = useNavigate(); // React Router hook for navigation

  useEffect(() => {
    const storedAdminId = sessionStorage.getItem("admin_id");
    setAdminId(storedAdminId);

    axios
      .get(`${BASE_URL_ADMIN}adminviewfamilypackage.php`)
      .then((response) => {
        if (response.data.success) {
          setPackages(response.data.data);
        } else {
          console.error("Failed to fetch packages:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching packages:", error);
      });
  }, []);

  const handlePackageClick = (packageId) => {
    navigate(`/familypackage_bookingdetails/${packageId}`);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="dashboard-container">
      <div className="topbar-container">
        <TopBar />
      </div>
      <div className="main-content">
        <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div className="content-area">
          <h1 className="adminadshboard-h1">Family Package Booking Details</h1>
          <div className="adminadshboard-section">
            {packages.length > 0 ? (
              <div className="adminadshboard-list">
                {packages.map((pkg, index) => (
                  <div
                    className="adminadshboard"
                    key={index}
                    onClick={() => handlePackageClick(pkg.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={pkg.package_image}
                      alt={pkg.Packagename}
                      className="adminadshboard_imgs"
                    />
                    <h3 className="adminadshboard-name">{pkg.packagename}</h3>
                    <p><b>Destination:</b> {pkg.destination}</p>
                    <p><b>Adult Perhead Price:</b> {pkg.adult_perheadprice}</p>
                    <p><b>Children Perhead Price:</b> {pkg.child_perheadprice}</p>
                    <p><b>Room Rent:</b> {pkg.roomamount}</p>

                  </div>
                ))}
              </div>
            ) : (
              <p className="no-packages-message">No packages available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Adminviewfamilypackage_booking;

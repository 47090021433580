import { useState } from "react";
import logo02 from "../images/New folder (4)/logo/logo01.png";
import "../css/Adminlogin.css";
import { useNavigate } from "react-router-dom";
import React from "react";
import { BASE_URL_ADMIN } from "../constants/constant";
import axios from "axios";

const AdminLogin = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({ email: "", password: "" }); // Error state
  const [errorMessage, setErrorMessage] = useState(""); // Generic error message
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateForm = () => {
    let valid = true;
    let tempErrors = { email: "", password: "" };

    if (!formData.email) {
      tempErrors.email = "Email is required.";
      valid = false;
    }

    if (!formData.password) {
      tempErrors.password = "Password is required.";
      valid = false;
    }

    setErrors(tempErrors);
    return valid;
  };

  const login = (e) => {
    e.preventDefault();
  
    if (!validateForm()) return;
  
    const { email, password } = formData;
  
    axios
      .post(`${BASE_URL_ADMIN}admin_login.php`, { email, password }, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response.data && response.data.admin_id) { // Use 'admin_id' from the response
          sessionStorage.setItem("admin_id", response.data.admin_id); // Save 'admin_id' in sessionStorage
          navigate("/admindashboard");
        } else {
          alert(response.data.error || "Login failed. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Login error:", error);
        alert("An error occurred while logging in. Please try again.");
      });
  };
  
  return (
    <div className="login-page">
      <div className="login-container">
        <div className="logo-section">
          <img src={logo02} alt="Logo" className="logo" />
        </div>
        <div className="login-form">
          <form onSubmit={login}>
            <label htmlFor="email">Your email</label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleInputChange}
              name="email"
            />
            {errors.email && <p className="error-message">{errors.email}</p>}
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleInputChange}
              name="password"
            />
            {errors.password && <p className="error-message">{errors.password}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button type="submit" className="sign-in-btn">
              Sign In
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;

import React, { useState } from "react";
import '../css/Loginform.css';
import { useNavigate, NavLink } from "react-router-dom";
import logo02 from '../images/New folder (4)/logo/logo02.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { BASE_URL } from "../constants/constant";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  
  const togglePasswordVisibility = () => setShowPassword(prev => !prev);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateForm = () => {
    let valid = true;
    let tempErrors = { email: "", password: "" };

    if (!formData.email) {
      tempErrors.email = "Email is required.";
      valid = false;
    }

    if (!formData.password) {
      tempErrors.password = "Password is required.";
      valid = false;
    }

    setErrors(tempErrors);
    return valid;
  };

  const login = (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const { email, password } = formData;

    axios
      .post(`${BASE_URL}login.php`, { email, password }, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response.data && response.data.user_id) {
          alert(response.data.message || "Login successful");
          sessionStorage.setItem("user_id", response.data.user_id);
          navigate("/loginsubmit");
        } else {
          alert(response.data.error || "Login failed. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Login error:", error);
        alert("An error occurred while logging in. Please try again.");
      });
  };
  
  const facebookIcon = (
    <FontAwesomeIcon icon={faFacebook} className="me-3 text-white" />
  );
  const googleIcon = (
    <FontAwesomeIcon icon={faGoogle} className="text-white" />
  );

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="card login-card">
        <div className="text-center mb-4">
          <img src={logo02} alt="logo" className="img-fluid" style={{ maxHeight: '100px' }} />
        </div>
        <form onSubmit={login}>
          <h2 className="text-center text-white mb-4">LOGIN</h2>

          <div className="mb-3">
            <input
              type="text"
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter Email"
            />
            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
          </div>

          <div className="mb-4 position-relative">
            <input
              type={showPassword ? "text" : "password"}
              className={`form-control ${errors.password ? "is-invalid" : ""}`}
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Enter Password"
            />
            <FontAwesomeIcon 
              icon={showPassword ? faEyeSlash : faEye} 
              onClick={togglePasswordVisibility} 
              className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer" 
              style={{ cursor: "pointer" }} 
            />
          </div>

          <button type="submit" className="row btn loginpage-submitbutton">
            Login
          </button>
        </form>

        <h4 className="text-center text-white">Or Sign Up With</h4>
        <div className="d-flex justify-content-center mb-3">
          {facebookIcon}
          {googleIcon}
        </div>

        <p className="text-center text-white">
          By creating an account, you agree to <b>LETS ENJOY Terms of Service</b> and <b>Privacy Policy</b>.
        </p>

        <NavLink to="/register" className="loginpage-navlink">
          Sign Up
        </NavLink>
      </div>
    </div>
  );
};

export default LoginForm;

import React from "react";
import { NavLink } from "react-router-dom";
import logo02 from '../images/New folder (4)/logo/logo02.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../Navbar/navbar.css';

function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg sticky-top navbar-page">
            <div className="container navbar-page-container">
                <NavLink to="/" className="navbar-brand">
                    <img src={logo02} alt="logo" className="navbar-logo" />
                </NavLink>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <NavLink to="/loginsubmit" className="nav-link">Home</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/loginsubmit" className="nav-link">Packages</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/about" className="nav-link">About Us</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/Contactus" className="nav-link">Contact Us</NavLink>
                        </li>
                        
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;

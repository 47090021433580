import React from 'react';
import { HashRouter  as Router, Route, Routes } from "react-router-dom";
import Homepage from './Navbar/Pages/Homepage';
import Destinations from './Navbar/Pages/Familypackage';
import Packages from './Navbar/Pages/Packages';
import Testimonials from './Navbar/Pages/Testimonials';
import Features from './Navbar/Pages/Feactures';
import Contact from './Navbar/Pages/Contact';





import About from './Navbar/Pages/About'; // Import the About component
import './css/App.css';
import ContactUs from './Navbar/Pages/ConstactUs';
import Contactussection2 from './Navbar/Pages/ContactusSection2';
import Seatbooking from './booking/seatbooking';
import Login from './Forms/Login'
import UserDetails from './Forms/UserDetails';
import Seatbooking02 from './booking/Seatbooking02';
import CreateAccount from './CreateAccount';
import CreateAccountForm from './Forms/Createaccountform';
import Navbar from './Navbar/Navbar';
import Billing from './booking/billing';
import UserDetailsForm from './Forms/UserDetailsForm';
import LoginForm from './Forms/LoginForm';
import AdminLogin from './Forms/AdminLogin';
import Admindashboard from './Navbar/Pages/Admindashboard';
import Sidebar from './sidebar/sidebar';
import TopBar from './Navbar/Pages/Topbar';
import PackageAdding from './Forms/Package_adding';
import UserProfile from './Navbar/Pages/Tourpackage_tickets';
import Adminviewpackage from './Navbar/Pages/adminviewpackage';
import BookingDetails from './Navbar/Pages/BookingDetails';
import RoomBooking from './Navbar/Pages/roombooking';
import Familypackageadding from './Forms/Familypackageadding';
import Adminviewfamilypackage from './Navbar/Pages/adminviewfamilypackage';
import Familypackage from './Navbar/Pages/Familypackage';
import Familypackage_roombooking from './Navbar/Pages/Familypackage_roombooking';
import Familypackage_booking from './Navbar/Pages/Familypackage_booking';
import Familypackagebilling from './booking/Familypackagebilling';
import Tourpackage_tickets from './Navbar/Pages/Tourpackage_tickets';
import Userprofile from './Navbar/Pages/Userprofile';
import Familypackage_tickets from './Navbar/Pages/Familypackage_tickets';
import Adminviewfamilypackage_booking from './Navbar/Pages/Adminviewfamilypackage_booking';
import Familypackage_bookingdetails from './Navbar/Pages/Familypackage_bookingdetails';
import LandingPage from './Navbar/Pages/LandingPage';


import TermsConditions from "./Navbar/Pages/TermsConditions";
import PrivacyPolicy from "./Navbar/Pages/PrivacyPolicy";
import RefundPolicy from "./Navbar/Pages/RefundPolicy";


function App() {
  return (
    <Router>
      <div className="App">
      <Routes>

      <Route path="/" element={<LandingPage />} />
        <Route path="/register" element={<CreateAccount />} />
        <Route path="/createaccount_submit" element={<UserDetails />} />
        <Route path="/UserDetailsform" element={<Login />} />
        <Route path="/loginpage" element={<Login />} />
        <Route path="/loginsubmit" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/Contactus" element={<ContactUs />} />
        <Route path="/seatbooking" element={<Seatbooking />} />
        <Route path="/seatbooking2" element={<Seatbooking02 />} />
        <Route path="/billing" element={<Billing />} />
        <Route path="/adminlogin" element={<AdminLogin />} />
        <Route path="/admindashboard" element={<Admindashboard />} />

        <Route path="/adminviewfamilypackage_booking" element={<Adminviewfamilypackage_booking />} />

        <Route path="/sidebar" element={<Sidebar />} />
        <Route path="/topbar" element={<TopBar />} />
        <Route path="/packageadding" element={<PackageAdding />} />
        <Route path="/familypackage" element={<Familypackageadding/>}/>
        <Route path="/familypackage_user" element={<Familypackage/>}/>
        <Route path="/adminviewfamilypackage" element={<Adminviewfamilypackage/>}/>
        <Route path="/userProfile" element={<Userprofile />} />

        <Route path="/familytourpackage_tickets" element={<Familypackage_tickets />} />
        <Route path="/familypackage_bookingdetails/:packageId" element={<Familypackage_bookingdetails />} />

        <Route path="/Tourpackage_tickets" element={<Tourpackage_tickets />} />
        <Route path="/adminviewpackage" element={<Adminviewpackage />} />
        <Route path="/bookingdetails/:packageId" element={<BookingDetails />} />

        <Route path="/roombooking" element={<RoomBooking/>}/>

        <Route path="destest" element={<Familypackage/>}/>

        <Route path="/testroom" element={<Familypackage_roombooking/>}/>

        <Route path="/familypackage_booking" element={<Familypackage_booking/>}/> 

        <Route path="/familypackagebilling" element={<Familypackagebilling/>}/>



        <Route path="/terms-and-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} /> 


        

      </Routes>
      </div>
    </Router>
  );
}

export default App;


import React from "react";
import "../../css/privacy.css"; 


function RefundPolicy() {
    return (
        <div className="privacy-container">
            <h1>🔒 Refund Policy </h1>

            <section>
            <p style={{ textAlign: "center" }}>As this is a service-based platform, we do not offer refunds.</p>
            </section>
        </div>
    );
}

export default RefundPolicy;

import React from "react";
import '../../css/Features.css';
import { FaTicketAlt, FaHeart, FaShieldAlt } from 'react-icons/fa';

function Features() {
 
    return (
      <div className="feature-maindiv">
       {/* Features Section */}
      <div className="features-section">
        <div className="feature-list">
          <div className="feature">
             <FaTicketAlt className="feature-icon"/>
           
            <p className="feature-des">Book Direct</p>
            <p className="feature-paragraph">"Quick, easy, and hassle-free online bus ticket booking."</p>
          </div>
          <div className="feature">
            <FaShieldAlt className="feature-icon" />
            <p className="feature-des">Keeping You Safe</p>
            <p className="feature-paragraph">"Ensuring your safety with reliable, secure travel options."</p>

          </div>
          <div className="feature">
            <FaHeart className="feature-icon"/>
            <p className="feature-des">Be Comfort</p>
            <p className="feature-paragraph">"Enjoy spacious seats and smooth, comfortable bus rides."</p>
          </div>
        </div>
      </div>

     

    </div>
  );
}

export default Features;

import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Navbar from "../Navbar";
import "../../css/Familypackage_booking.css";
import Familypackage_roombooking from "./Familypackage_roombooking";
import swift_5_seater from "../../images/vehicles/swift_5_seater.png";
import innova_7_seater from "../../images/vehicles/innova_7_seater.png";
import traveller_12_seater_ac from "../../images/vehicles/12_seater_traveller_ac.png";
import tourist_bus_33_seat from "../../images/vehicles/tourist_bus_33_seat__2+2_.png";
import benz_bus_35_seat from "../../images/vehicles/benz_bus_35_seat.png";
import benz_premimum_luxury_pushback_45_seater_ac from "../../images/vehicles/benz_premimum_luxury_pushback_45_seater_ac_2+2.png";
import { BASE_URL } from "../../constants/constant";

// Map seating capacity to corresponding vehicle image
const vehicleImagesByCapacity = {
  4: swift_5_seater,
  6: innova_7_seater,
  12: traveller_12_seater_ac,
  33: tourist_bus_33_seat,
  35: benz_bus_35_seat,
  45: benz_premimum_luxury_pushback_45_seater_ac,
};

const Familypackage_booking = () => {
  const [adults, setAdults] = useState(4);
  const [children, setChildren] = useState(0);
  const [childrenAges, setChildrenAges] = useState([]);
  const [rooms, setRooms] = useState(1);
  const [packageDetails, setPackageDetails] = useState(null);
  const [tripStartDate, setTripStartDate] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const { packageId } = useLocation().state || {};

  useEffect(() => {
    if (packageId) {
      const totalPassengers = adults + children;

      fetch(`${BASE_URL}/familypackagedetails_fetch.php?packageId=${packageId}&adults=${adults}`)
        .then((response) => response.json())
        .then((data) => {
          if (!data.error) {
            setPackageDetails(data.package);

            // Filter vehicles with seating capacity >= totalPassengers
            const availableVehicles = data.vehicles.filter((vehicle) => {
              const vehicleSeatingCapacity = parseInt(vehicle.seatingcapacity);
              return vehicleSeatingCapacity >= totalPassengers;
            });

            setVehicles(availableVehicles);
          } else {
            console.error("Error fetching package details:", data.error);
          }
        })
        .catch((error) => console.error("Error:", error));
    }
  }, [packageId, adults, children]);

  const calculatePayments = (vehicle) => {
    if (!packageDetails || !vehicle) return { totalPackagePrice: 0, advancePayment: 0 };

    const totalPackagePrice = (
      adults * parseFloat(packageDetails.perheadprice) +
      children * parseFloat(packageDetails.child_perheadprice) +
      parseFloat(packageDetails.roomamount) * rooms +
      parseFloat(vehicle.vehicle_price)
    ).toFixed(2);

    const advancePayment = (
      (parseFloat(packageDetails.perheadprice) * adults +
        parseFloat(vehicle.vehicle_price)) *
      (parseFloat(packageDetails.advance_payment_percentage) / 100)
    ).toFixed(2);

    return { totalPackagePrice, advancePayment };
  };

  return (
    <div>
      <Navbar />
      <NavLink to="/loginsubmit" className="back-button">
        ←
      </NavLink>

      {packageDetails && (
        <div className="Familypackage_booking_details">
          <h4>{packageDetails.packagename}</h4>
          <p>Destination: {packageDetails.destination}</p>
          <p>Per Head Price Adult: {packageDetails.perheadprice}</p>
          <p>Children per head price: {packageDetails.child_perheadprice}</p>
          <p>Room Rent: {packageDetails.roomamount}</p>

          <label htmlFor="trip_start_date" className="form-label">
            Trip Start Date
          </label>
          <input
            type="date"
            id="trip_start_date"
            name="trip_start_date"
            value={tripStartDate}
            onChange={(e) => setTripStartDate(e.target.value)}
            min={new Date().toISOString().split("T")[0]}
          />
        </div>
      )}

      <Familypackage_roombooking
        setAdults={setAdults}
        adults={adults}
        children={children}
        childrenAges={childrenAges}
        setChildren={setChildren}
        setChildrenAges={setChildrenAges}
        setRooms={setRooms}
      />

      {vehicles.length > 0 ? (
        <div className="Familypackage_booking-vehicles">
          {vehicles.map((vehicle) => {
            const { totalPackagePrice, advancePayment } = calculatePayments(vehicle);
            return (
              <div key={vehicle.vehicle_id} className="vehicle-item">
                <img
                  src={vehicleImagesByCapacity[parseInt(vehicle.seatingcapacity)]}
                  alt={vehicle.vehiclename}
                  className={`vehicle-image ${vehicle.vehiclename}`}
                />
                <div className="vehicle-details">
                  <h5>Vehicle Name: {vehicle.vehiclename}</h5>
                  <p>Price: ₹{vehicle.vehicle_price}</p>
                  <p>Seating Capacity: {vehicle.seatingcapacity} seats</p>
                </div>

                <div className="payment-details">
                  <h5>Total Package Price: ₹{totalPackagePrice}</h5>
                  <h5>Advance Payment: ₹{advancePayment}</h5>

                  <NavLink
                    to="/familypackagebilling"
                    className={`proceed-button ${tripStartDate ? "" : "disabled"}`}
                    state={{
                      totalPackagePrice,
                      advancePayment,
                      roomrent: packageDetails.roomamount,
                      perheadprice: packageDetails.perheadprice,
                      childrenPerHeadPrice: packageDetails.child_perheadprice,
                      rooms,
                      adults,
                      children,
                      vehicleId: vehicle.vehicle_id,
                      vehicleName: vehicle.vehiclename,
                      packageId,
                      tripStartDate,
                    }}
                    style={{
                      pointerEvents: tripStartDate ? "auto" : "none",
                      opacity: tripStartDate ? 1 : 0.5,
                    }}
                  >
                    Proceed to Billing
                  </NavLink>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p className="no-vehicles-message">
          No vehicles available with sufficient seating capacity for {adults + children} passengers.
        </p>
      )}
    </div>
  );
};

export default Familypackage_booking;
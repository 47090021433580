import React, { useState, useEffect } from "react";
import "../../css/Destinations.css";
import axios from "axios";
import { BASE_URL } from "../../constants/constant";
import { NavLink } from "react-router-dom";

function Familypackage() {
  const [destinations, setDestinations] = useState([]);

  useEffect(() => {
    // Fetch package data from PHP API
    axios
      .get(`${BASE_URL}viewfamilypackage_user.php`)
      .then((response) => {
        if (response.data.success) {
          setDestinations(response.data.data); // Set the destinations state with the fetched data
        } else {
          console.error("Failed to fetch packages:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching packages:", error);
      });
  }, []);

  return (
    <div className="destination-maindiv">
      <h2 className="destination-h2">
        <b>Family Tour Packages</b>
      </h2>

      <div className="destination-section">
        <div className="destination-list">
          {destinations.length > 0 ? (
            destinations.map((pkg, index) => (
              <div className="destination" key={index}>
                <img
                  src={pkg.package_image}
                  alt={pkg.packagename}
                  className="imgs"/>
                <p className="destination-name">{pkg.packagename}</p>
                <p className="destination-name">Adult Per Head Price: {pkg.adult_perheadprice}</p>
                <p className="destination-name">Child Per Head Price: {pkg.child_perheadprice}</p>

              <NavLink
              to="/familypackage_booking"
              state={{ packageId: pkg.id }} // Pass packageId in state
              className="destination-navlink"
            >
              View Package
            </NavLink>


               
              </div>
            ))
          ) : (
            <p>No packages available</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Familypackage;

import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../constants/constant";
import "../../css/Usertickets.css";
import { Pdfgenerator_tourpackage } from "../../Navbar/Pages/Pdfgeneratorfor_tourpackage";

const Tourpackage_tickets = () => {
  const [userId, setUserId] = useState("");
  const [bookings, setBookings] = useState([]);
  const [error, setError] = useState(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(2); // Get the last 2 digits of the year

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const ampm = hours >= 12 ? "PM" : "AM"; // Determine AM or PM
    hours = hours % 12 || 12; // Convert to 12-hour format (0 becomes 12)

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  };

  useEffect(() => {
    const storedUserId = sessionStorage.getItem("user_id");
    if (storedUserId) {
      setUserId(storedUserId);

      fetch(`${BASE_URL}tourpackage_tickets.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: storedUserId }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          if (data.error) {
            setError(data.error);
          } else {
            setBookings(data);
          }
        })
        .catch((err) => {
          console.error("Fetch error:", err);
          setError("Failed to fetch booking details.");
        });
    } else {
      setError("User ID not found in session storage.");
    }
  }, []);

  return (
    <div>
      {error ? (
        <p className="user-profile-error-message">{error}</p>
      ) : (
        <div className="user-profile-booking-container">
          <h2>Tour Package Tickets</h2>
          {bookings.length > 0 ? (
            bookings.map((booking, index) => (
              <div key={index} className="booking-card">
                <div className="card-title">{booking.package_name}</div>
                <div className="card-detail">
                  <span>Departure Time:</span>
                  <span>{formatDate(booking.departure_time)}</span>
                </div>
                <div className="card-detail">
                  <span>Seat Names:</span>
                  <span>{booking.seats.join(", ")}</span>
                </div>

               

                <div className="card-detail">
                  <span>Adults:</span>
                  <span>{booking.adults}</span>
                </div>
                <div className="card-detail">
                  <span>Children:</span>
                  <span>{booking.children}</span>
                </div>
                <div className="card-detail">
                  <span>Rooms:</span>
                  <span>{booking.rooms}</span>
                </div>
                <div className="card-detail">
                  <span>Total Package Amount:</span>
                  <span>₹{booking.packagetotal}</span>
                </div>
                <div className="card-detail">
                  <span>Total Amount To Be Paid:</span>
                  <span>₹{booking.balanceto_be_paid}</span>
                </div>
                <button
                  className="download-ticket-button"
                  onClick={() => Pdfgenerator_tourpackage(booking)}
                >
                  Download Ticket
                </button>
              </div>
            ))
          ) : (
            <p>No Bookings Found For Tour Package.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Tourpackage_tickets;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TopBar from "./Topbar";
import Sidebar from "../../sidebar/sidebar";
import "../../css/Bookingdetails.css"; // Link to the CSS file
import axios from "axios";
import { BASE_URL_ADMIN } from "../../constants/constant";

const Familypackage_bookingdetails = () => {
  const [adminId, setAdminId] = useState(null);
  const { packageId } = useParams();
  const [bookingDetails, setBookingDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true); // Track sidebar open/closed state
  const [packageName, setPackageName] = useState(""); // To store the package name

  useEffect(() => {
    const storedAdminId = sessionStorage.getItem("admin_id");
    setAdminId(storedAdminId);

    axios
      .post(`${BASE_URL_ADMIN}familypackage_booking_details.php`, { package_id: packageId })
      .then((response) => {
        if (response.data.success) {
          setBookingDetails(response.data.data);

          // Extract and set the package name (assuming it's in the response data)
          if (response.data.data.length > 0) {
            setPackageName(response.data.data[0].packagename || "Booking Details");
          }
        } else {
          console.error("No bookings found:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching booking details:", error);
      })
      .finally(() => setLoading(false));
  }, [packageId]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handlePaymentUpdate = (booking) => {
    const requestData = {
      package_id: packageId,
      timestamp: booking.booking_timestamp, // Assume timestamp exists in the booking data
      payment_status: "paid",
    };

    axios
      .post(`${BASE_URL_ADMIN}balancepayment_familypackage.php`, requestData)
      .then((response) => {
        if (response.data.success) {
          window.location.reload(); // Reload the current page

          // Update the local bookingDetails state to reflect the change
          setBookingDetails((prevDetails) =>
            prevDetails.map((item) =>
              item.booking_timestamp === booking.booking_timestamp
                ? { ...item, balance_payment: "paid" }
                : item
            )
          );
        } else {
          alert("Failed to update payment status: " + response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error updating payment status:", error);
        alert("An error occurred while updating the payment status.");
      });
  };

  return (
    <div className="dashboard-container">
      <div className="topbar-container">
        <TopBar />
      </div>
      <div className="main-content">
        <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />

        <div className="content-area">
          <h1 className="booking-details-heading1">
            {packageName} Bookings
          </h1>

          {loading ? (
            <p>Loading booking details...</p>
          ) : bookingDetails.length > 0 ? (
            <table className="booking-details-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Package Booked Date</th>
                  <th>Package Starting Date</th>
                  <th>Phone Number</th>
                  <th>Advance Amount Paid</th>
                  <th>Total Package Amount</th>
                  <th>Balance Amount To Be Paid</th>
                  <th>Balance Amount Payment Status</th>
                </tr>
              </thead>
              <tbody>
                {bookingDetails.map((booking, index) => {
                  const balancePayment = booking.balance_payment.trim(); // Trim to ensure no extra spaces
                  const isPaid = balancePayment === "paid";

                  return (
                    <tr key={index}>
                      <td data-label="User Name">{booking.first_name}</td>
                      <td data-label="Booked Date">
                        {new Date(booking.booking_timestamp).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}
                      </td>
                      <td data-label="Starting Date">
                        {new Date(booking.trip_start_date).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}
                      </td>
                      <td data-label="Phone Number">{booking.phone_number}</td>
                      <td data-label="Advance Payment">{booking.advance_payment}</td>
                      <td data-label="Total Package Amount">{booking.total_package_amount}</td>
                      <td data-label="Balance Amount">{booking.balance_payment_amount}</td>
                      <td data-label="Payment Status">
                        {isPaid ? (
                          <p className="paid-status">Paid</p>
                        ) : (
                          <button
                            className="booking-details-button"
                            onClick={() => handlePaymentUpdate(booking)}
                          >
                            Pending
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p>No bookings found for this package.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Familypackage_bookingdetails;

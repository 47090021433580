import React, { useRef, useEffect } from "react";
import '../../css/homepage.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { FaEnvelope, FaMapMarkerAlt, FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { NavLink, useNavigate } from "react-router-dom";
import Features from "./Feactures";
import Packages from './Packages';
import Testimonials from './Testimonials';
import Contact from './Contact';
import Familypackage from "./Familypackage";

// Import images for the slideshow
import bus from '../../images/New folder (4)/04.png';
import logo02 from '../../images/New folder (4)/logo/LETSENJOY 2-01-2_High quality.png';


function Homepage() {
  const familypackageRef = useRef(null);
  const packagesRef = useRef(null);
  const homeRef = useRef(null);
  const navigate = useNavigate();

  // Check if user_id exists in sessionStorage when the component mounts
  useEffect(() => {
    const storedUserId = sessionStorage.getItem("user_id");
    if (!storedUserId) {
      navigate("/"); // Redirect to the root if user_id is not found
    }
  }, [navigate]);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  return (
    <div className="container-fluid p-0">
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg custom-navbar">
        <div className="container homepage-nav-container">
            <img src={logo02} alt="logo02" className="logo img-fluid" />
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <NavLink onClick={() => scrollToSection(familypackageRef)} className="nav-link">Family Tour Packages</NavLink>
              </li>
              <li className="nav-item">
                <NavLink onClick={() => scrollToSection(packagesRef)} className="nav-link">Tour Packages</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/userProfile" className="nav-link">Profile</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/about" className="nav-link">About Us</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/Contactus" className="nav-link">Contact Us</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/" onClick={handleLogout} className="nav-link">Logout</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Background Image */}
      <div className="text-center">
        <img src={bus} alt="Bus" className="img-fluid w-100" style={{ maxHeight: '410px', objectFit: 'cover' }} />
      </div>

      {/* Features Section */}
      <Features />

      {/* Destination Section */}
      <div ref={familypackageRef}>
        <Familypackage />
      </div>

      {/* Packages Section */}
      <div ref={packagesRef}>
        <Packages />
      </div>

      <Testimonials />

      <Contact />
    </div>
  );
}

export default Homepage;

import React from "react";
import '../../css/contactussection2.css';
import { FaEnvelope, FaPhone, FaMapMarker } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';

function Contactussection2() {
    return (
        <div className="container py-4">
            <div className="row justify-content-between">
                
                {/* Form Section */}
                <div className="col-md-6 mb-4">
                    <form>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label>Name</label>
                                <input type="text" className="form-control" placeholder="Enter Full Name" />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Phone Number</label>
                                <input type="text" className="form-control" placeholder="Your Phone Number" />
                            </div>
                        </div>
                        
                        <div className="mb-3">
                            <label>Email</label>
                            <input type="email" className="form-control" placeholder="Your Email" />
                        </div>

                        <div className="mb-3">
                            <label>Subject</label>
                            <input type="text" className="form-control" placeholder="Your Subject" />
                        </div>

                        <div className="mb-3">
                            <label>Message</label>
                            <textarea className="form-control" rows="4" placeholder="Enter Message"></textarea>
                        </div>

                        <button type="submit" className="btn btn-primary w-100">Submit</button>
                    </form>
                </div>

                {/* Contact Details and Map */}
                <div className="col-md-5">
                    <h2>Get in Touch</h2>
                    
                    <div className="d-flex align-items-center mb-3">
                        <FaEnvelope className="me-2 text-white bg-primary p-2 rounded" />
                        <p className="mb-0">letsenjoy4tours@gmail.com</p>
                    </div>
                    
                    <div className="d-flex align-items-center mb-3">
                        <FaPhone className="me-2 text-white bg-primary p-2 rounded" />
                        <p className="mb-0">+91 7034747568</p>
                    </div>
                    
                    <div className="d-flex align-items-start mb-3">
                        <FaMapMarker className="me-2 text-white bg-primary p-2 rounded" />
                        <p className="mb-0">Address: Let's Enjoy Tourplanners,first floorKandanatt Jhon k Antony Arcade, Kothanalloor, Kerala 686632</p>
                    </div>

                    <h2>Current Location</h2>
                    <iframe
                        width="100%"
                        height="250"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3737.9751855706054!2d76.52601797478971!3d9.718991790372556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b07d5509ac1c4b1%3A0x35d7c509056b54a9!2sLet&#39;s%20Enjoy%20Tourplanners!5e1!3m2!1sen!2sin!4v1737957387510!5m2!1sen!2sin"
                        loading="lazy"
                        title="Location Map"
                    ></iframe>
                </div>
            </div>
        </div>
    );
}

export default Contactussection2;

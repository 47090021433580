import React from "react";
import image1 from '../../images/New folder (4)/09.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/contactus.css';
import Navbar from "../Navbar";
import Contact from "./Contact";
import Contactussection2 from "./ContactusSection2";

function ContactUs() {
    return (
        <div className="contactus-container">
            <Navbar />
            
            <div className="position-relative">
                <img src={image1} alt="seats" className="img-fluid w-100" />
                <h1 className="contactus-head1 text-center">Contact Us</h1>
                {/* <p className="contactus-para1 text-center">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla.
                </p> */}
            </div>
            
            <div className="container my-4">
                <div className="row">
                    <div className="col-12">
                        <Contactussection2 />
                    </div>
                </div>
            </div>
            <Contact />
        </div>
    );
}

export default ContactUs;

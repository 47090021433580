import React, { useState, useEffect } from "react";
import '../../css/landingpage.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { NavLink } from "react-router-dom";
import logo02 from '../../images/New folder (4)/logo/LOGO PNG FORMAT WITH TAGLINE (1).png';
import slideshowimg1 from '../../images/New folder (4)/01.png';
import slideshowimg2 from '../../images/New folder (4)/02.png';
import slideshowimg3 from '../../images/New folder (4)/03.png';
import Contact from './Contact';
import FamilyPackageview from "./FamilyPackagesview";
import TourPackageview from "./TourPackageview";
import Testimonials from "./Testimonials";

function LandingPage() {
  const images = [slideshowimg1, slideshowimg2, slideshowimg3];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");  // Track the search input

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);  // Update the search query when user types
  };

  return (
    <div>
      <div className="landingpagelogo">
        <img src={logo02} alt="logo02" className="landingpagelogo" />
      </div>

      <div className="register_login">
        <NavLink to="/register" className="register">Register</NavLink>
        <NavLink to="/loginpage" className="login">Login</NavLink>
      </div>

      <div className="slideshow-container">
        {images.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`Slide ${index + 1}`}
            className={`slide ${index === currentImageIndex ? 'fade active' : 'fade'}`}
            style={{ display: index === currentImageIndex ? 'block' : 'none' }}
          />
        ))}
      </div>

      <h1 className="welcome">"Skip the line, Book Your <br /> <span className="span">Ride Online!"</span></h1>
      <p className="desc">
        Book your tickets in just a few clicks! Enjoy hassle-free reservations,<br />
        <span className="span">secure payments, and real-time seat availability for your next journey</span>
      </p>

      <div className="search-container">
        <input
          type="text"
          className="search-input"
          placeholder="Search for your destination..."
          value={searchQuery}
          onChange={handleSearchChange}  // Track input change
        />
        <button className="search-button">Search</button>
      </div>

      <TourPackageview  searchQuery={searchQuery} />
      {/* Pass the searchQuery prop to FamilyPackageview */}
      <FamilyPackageview searchQuery={searchQuery} />

      <Testimonials />
      <Contact />
    </div>
  );
}

export default LandingPage;

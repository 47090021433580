import React, { useEffect, useState } from 'react';
import '../../css/Adminviewpackages.css';
import { useNavigate } from "react-router-dom";

import axios from 'axios';
import { BASE_URL } from "../../constants/constant";
import TopBar from './Topbar';
import Sidebar from '../../sidebar/sidebar';

import { BASE_URL_ADMIN } from "../../constants/constant";


function Adminviewfamilypackage() {
  const [family_package, setPackages] = useState([]);
  const [adminId, setAdminId] = useState(null);
  const navigate = useNavigate(); // React Router hook for navigation
  

  useEffect(() => {

    const storedAdminId = sessionStorage.getItem("admin_id");
    if (!storedAdminId) {
      // Redirect to the login page if admin_id is not found
      navigate("/adminlogin");
    } else {
      setAdminId(storedAdminId);
    }

    axios.get(`${BASE_URL}viewfamilypackage.php`)
      .then(response => {
        if (response.data.success) {
          setPackages(response.data.data);
        } else {
          console.error("Failed to fetch packages:", response.data.message);
        }
      })
      .catch(error => {
        console.error("Error fetching packages:", error);
      });
  }, []);

  const handlepackagestatus = (pkg) => {
    const requestData = {
      package_id: pkg.package_id,
      package_status: "deactive",
    };

    axios
      .post(`${BASE_URL_ADMIN}familypackagedeactivation.php`, requestData)
      .then((response) => {
        if (response.data.success) {
          window.location.reload(); // Reload the current page
      
          
        } else {
          alert("Failed to update  status: " + response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        alert("An error occurred while updating  status.");
      });
  };

  return (
    <div className="dashboard-container">
      <div className="topbar-container">
        <TopBar />
      </div>
      <div className="main-content">
        <Sidebar />
        <div className="content-area">
          <h1 className="adminview_packages-h1">Packages</h1>
          <div className="adminview_packages-section">
            {family_package.length > 0 ? (
              <div className="adminview_packages-list">
                {family_package.map((pkg, index) => (
                  <div className="adminview_packages" key={index}>
                    <img 
                      src={pkg.package_image} 
                      alt={pkg.packagename} 
                      className="adminview_imgs" 
                    />
                    <h3 className="adminview_packages-name">{pkg.packagename}</h3>
                    <p className="adminview_packagedetails"><b>Destination:</b> {pkg.destination}</p>
                    <p className="adminview_packagedetails"><b>Adult Perhead Price:</b> {pkg.adult_perheadprice}</p>
                    <p className="adminview_packagedetails"><b>Children Perhead Price:</b> {pkg.child_perheadprice}</p>
                    
                    {/* Display all the vehicles related to the package */}
                    {pkg.vehicles && pkg.vehicles.length > 0 && (
                      <div className="adminview_vehicle-details">
                        <h4>Vehicle Details</h4>
                        {pkg.vehicles.map((vehicle, idx) => (
                          <div key={idx}>
                            <p><b>Vehicle Name:</b> {vehicle.vehiclename}</p>
                            <p><b>Price:</b> {vehicle.vehicle_price}</p>
                            <p><b>Seating Capacity:</b> {vehicle.seatingcapacity}</p>
                          </div>
                        ))}
                      </div>
                    )}
                    
                    <button className="booking-details-button" onClick={() => handlepackagestatus(pkg)}>
                      Deactivate Package
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <p style={{ textAlign: 'center', fontSize: '18px', color: '#666' }}>
                No packages available.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adminviewfamilypackage;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import TopBar from "./Topbar";
import Sidebar from "../../sidebar/sidebar";
import "../../css/Bookingdetails.css"; // Link to the CSS file
import axios from "axios";
import { BASE_URL_ADMIN } from "../../constants/constant";

const BookingDetails = () => {
  const [adminId, setAdminId] = useState(null);
  const { packageId } = useParams();
  const [bookingDetails, setBookingDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true); // Track sidebar open/closed state
  const navigate = useNavigate(); // React Router hook for navigation

  useEffect(() => {
    const storedAdminId = sessionStorage.getItem("admin_id");
    if (!storedAdminId) {
      // Redirect to the login page if admin_id is not found
      navigate("/adminlogin");
    } else {
      setAdminId(storedAdminId);
    }

    axios
      .post(`${BASE_URL_ADMIN}booking_details.php`, { package_id: packageId })
      .then((response) => {
        if (response.data.success) {
          setBookingDetails(response.data.data);
        } else {
          console.error("No bookings found:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching booking details:", error);
      })
      .finally(() => setLoading(false));
  }, [packageId]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handlePaymentUpdate = (booking) => {
    const requestData = {
      package_id: packageId,
      timestamp: booking.timestamp, // Assume timestamp exists in the booking data
      payment_status: "paid",
    };

    axios
      .post(`${BASE_URL_ADMIN}balancepaymnet.php`, requestData)
      .then((response) => {
        if (response.data.success) {
          window.location.reload(); // Reload the current page
      

          // Update the local bookingDetails state to reflect the change
          setBookingDetails((prevDetails) =>
            prevDetails.map((item) =>
              item.timestamp === booking.timestamp
                ? { ...item, balance_payment: "paid" }
                : item
            )
          );
        } else {
          alert("Failed to update payment status: " + response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error updating payment status:", error);
        alert("An error occurred while updating the payment status.");
      });
  };

  return (
    <div className="dashboard-container">
      <div className="topbar-container">
        <TopBar />
      </div>
      <div className="main-content">
        <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />

        <div className="content-area">
          <h1 className="booking-details-heading1">Booking Details</h1>

          {loading ? (
            <p>Loading booking details...</p>
          ) : bookingDetails.length > 0 ? (
            <table className="booking-details-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Ticket booked Date</th>
                  <th>Phone Number</th>
                  <th>Seats Booked</th>
                  <th>Entry Place </th>
                  <th>Advance Amount Paid</th>
                  <th>Total Package Amount</th>
                  <th>Balance Amount To Be Paid</th>
                 

                  
                  <th>Balance Amount Payment Status</th>
                  
                </tr>
              </thead>
              <tbody>
                {bookingDetails.map((booking, index) => {
                  const balancePayment = booking.balance_payment.trim(); // Trim to ensure no extra spaces
                  const isPaid = balancePayment === "paid";

                  return (
                    <tr key={index}>
                      <td data-label="User Name">{booking.first_name}</td>
                      <td data-label="booked_date">
                        {new Date(booking.timestamp).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        })}
                      </td>


                      
                      <td data-label="Phone Number">{booking.phone_number}</td>
                      <td data-label="Seats Booked">{booking.seats_booked}</td>
                      <td data-label="Balance Amount">{booking.entryplace_name}</td>
                      <td data-label="Advance Amount Paid">{booking.adcanceamountpaid}</td>
                      <td data-label="Total Package Price">{booking.packagetotal}</td>
                      <td data-label="Balance Amount">{booking.balance_amount}</td>
                      

                      
                      <td data-label="Payment Status">
                        {isPaid ? (
                          <p className="paid-status">Paid</p>
                        ) : (
                          <button className="booking-details-button"
                            onClick={() => handlePaymentUpdate(booking)}
                          >
                            Pending
                          </button>
                        )}
                      </td>
                     

                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p>No bookings found for this package.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;

import React, { useState, useEffect } from 'react';
import '../../css/Roombooking.css';
import people from "../../images/New folder (4)/people.png";

function Familypackage_roombooking({ setAdults, adults, children, childrenAges, setChildren, setChildrenAges, setRooms }) {
  const [showDetails, setShowDetails] = useState(false);
  const [rooms, setLocalRooms] = useState(1);

  const MAX_ADULTS_PER_ROOM = 3;
  const MAX_CHILDREN_PER_ROOM = 3;

  const calculateRooms = (totalAdults, totalChildren) => {
    const requiredRoomsForAdults = Math.ceil(totalAdults / MAX_ADULTS_PER_ROOM);
    const requiredRoomsForChildren = Math.ceil(totalChildren / MAX_CHILDREN_PER_ROOM);
    return Math.max(requiredRoomsForAdults, requiredRoomsForChildren);
  };

  useEffect(() => {
    const requiredRooms = calculateRooms(adults, children);
    setLocalRooms(requiredRooms);  // Update rooms locally
    setRooms(requiredRooms); // Update rooms in parent component
  }, [adults, children, setRooms]);

  const handleAdultChange = (change) => {
    const newAdults = Math.max(0, adults + change);
    setAdults(newAdults); // Update adults directly in parent component
  };

  const handleChildChange = (change) => {
    const newChildren = Math.max(0, children + change);
    setChildren(newChildren);
   
  };

 

  const resetForm = () => {
    setAdults(0);
    setChildren(0);
    setRooms(1);
    setShowDetails(false);
  };

  return (
    <div>
      <div className="roombooking-main-div" onClick={() => setShowDetails(!showDetails)}>
        <h1 className='roombooking-h1'>Room Booking & Vehicle </h1>
        <img src={people} alt="people" className="roomboooking-icon" />
        <p className="roombooking-p1">Guests and Rooms </p>
      </div>

      {showDetails && (
        <div className="roombooking-details">
          <div className="roombooking-row">
            <span>Adults</span>
            <button onClick={() => handleAdultChange(-1)}>-</button>
            <span>{adults}</span>
            <button onClick={() => handleAdultChange(1)}>+</button>
          </div>
          <div className="roombooking-row">
            <span>Children</span>
            <button onClick={() => handleChildChange(-1)}>-</button>
            <span>{children}</span>
            <button onClick={() => handleChildChange(1)}>+</button>
          </div>
          {children > 0 && childrenAges.map((age, index) => (
            <div key={index} className="roombooking-row">
              <span>Child {index + 1} Age</span>
              <input
                type="number"
                min="0"
                max="18"
                
            
              />
            </div>
          ))}
          <div className="roombooking-row">
            <span>Rooms</span>
            <span>{rooms}</span>
          </div>
          <div className="roombooking-buttons">
            <button onClick={resetForm}>Reset</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Familypackage_roombooking;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "./Topbar";
import Sidebar from "../../sidebar/sidebar";
import "../../css/admindashboard.css"; // Updated CSS for styling
import { BASE_URL } from "../../constants/constant";
import axios from "axios";

const Admindashboard = () => {
  const [adminId, setAdminId] = useState(null);
  const [packages, setPackages] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(true); // Track sidebar open/closed state
  const navigate = useNavigate(); // React Router hook for navigation

  useEffect(() => {
    const storedAdminId = sessionStorage.getItem("admin_id");
    if (!storedAdminId) {
      // Redirect to the login page if admin_id is not found
      navigate("/adminlogin");
    } else {
      setAdminId(storedAdminId);

      // Fetch packages data
      axios
        .get(`${BASE_URL}viewactivepackage.php`)
        .then((response) => {
          if (response.data.success) {
            setPackages(response.data.data);
          } else {
            console.error("Failed to fetch packages:", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error fetching packages:", error);
        });
    }
  }, [navigate]);

  const handlePackageClick = (packageId) => {
    navigate(`/bookingdetails/${packageId}`);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="dashboard-container">
      <div className="topbar-container">
        <TopBar />
      </div>
      <div className="main-content">
        <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div className="content-area">
          <h1 className="adminadshboard-h1">Booking Details</h1>
          <div className="adminadshboard-section">
            {packages.length > 0 ? (
              <div className="adminadshboard-list">
                {packages.map((pkg, index) => (
                  <div
                    className="adminadshboard"
                    key={index}
                    onClick={() => handlePackageClick(pkg.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={pkg.package_image}
                      alt={pkg.Packagename}
                      className="adminadshboard_imgs"
                    />
                    <h3 className="adminadshboard-name">{pkg.Packagename}</h3>
                    <p><b>Source:</b> {pkg.source}</p>
                    <p><b>Destination:</b> {pkg.destination}</p>
                    <p><b>Bus Layout:</b> {pkg.bus_layout}</p>
                  </div>
                ))}
              </div>
            ) : (
              <p className="no-packages-message">No packages available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admindashboard;

import React, { useEffect, useState } from 'react';
import '../../css/Packages.css';
import { FaLocationArrow } from 'react-icons/fa';
import { NavLink } from "react-router-dom";
import axios from 'axios';
import { BASE_URL } from "../../constants/constant";

function TourPackageview({ searchQuery = "" }) {  // Default to empty string if searchQuery is undefined
  const [packages, setPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]);

  useEffect(() => {
    axios.get(`${BASE_URL}viewpackage_user.php`)
      .then(response => {
        if (response.data.success) {
          setPackages(response.data.data);
          setFilteredPackages(response.data.data);  // Initialize with all packages
        } else {
          console.error("Failed to fetch packages:", response.data.message);
        }
      })
      .catch(error => {
        console.error("Error fetching packages:", error);
      });
  }, []);

  // Filter packages whenever searchQuery or packages change
  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredPackages(packages);  // Show all if search is empty
    } else {
      const filtered = packages.filter(pkg =>
        pkg.destination && pkg.destination.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredPackages(filtered);
    }
  }, [searchQuery, packages]);

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    return `${date.toLocaleDateString("en-GB", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
            at ${date.toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit', hour12: true })}`;
  };

  return (
    <div className="packages-maindiv">
      <h2 className="packages-h2_landingpage"><b>Tour Packages</b></h2>

      <div className="packages-section">
        <div className="packages-list">
          {filteredPackages.length > 0 ? (
            filteredPackages.map((pkg, index) => (
              <div className="packages" key={index}>
  <img 
    src={pkg.package_image.replace("http://", "https://")} 
    alt={pkg.Packagename} 
    className="imgs" 
  />
  <p className="packages-name">{pkg.Packagename}</p>
  <p className='package-days'>Departure: {formatDateTime(pkg.departure_time)}</p>
  <p className='package-days'>Arrival: {formatDateTime(pkg.arrival_time)}</p>
  <p className='package-location'>
    <FaLocationArrow />
    {pkg.source} to {pkg.destination}
  </p>

  {pkg.bustype_id === "1" ? (
    <NavLink to="/loginpage" state={{ packageId: pkg.id, bustype: pkg.bustype_id, seatprice: pkg.advanve_payment }} className="packages-navlink">Book Now</NavLink>
  ) : pkg.bustype_id === "2" ? ( 
    <NavLink to="/loginpage" state={{ packageId: pkg.id, bustype: pkg.bustype_id, seatprice: pkg.advanve_payment }} className="packages-navlink">Book Now</NavLink>
  ) : (
    <p>No booking available for this package</p>
  )}
</div>

            ))
          ) : (
            <p>No packages related to this place are available.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default TourPackageview;

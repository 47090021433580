import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import seatIcon from "../images/New folder (4)/bus/seat.png";
import steering from "../images/New folder (4)/bus/stearing wheel.png";
import "../css/seatbooking02.css";
import Contact from "../Navbar/Pages/Contact";
import RoomBooking from "../Navbar/Pages/roombooking";
import { BASE_URL } from "../../src/constants/constant"; // Assuming BASE_URL is defined for API calls

const Seatbooking02 = () => {
    const location = useLocation();
    const { packageId, bustype, seatprice } = location.state || {};

    const [seats, setSeats] = useState([]);
    const [selectedSeats, setSelectedSeats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [packageDetails, setPackageDetails] = useState(null);
    const [totalGuests, setTotalGuests] = useState(0);
    const [maxSeats, setMaxSeats] = useState(0);
    const [entryPoints, setEntryPoints] = useState([]); // State for entry points
    const [selectedEntryPoint, setSelectedEntryPoint] = useState(""); // Selected entry point
    const [adults, setAdults] = useState(0);
    const [children, setChildren] = useState(0);
    const [rooms, setRooms] = useState(0);

    const pricePerSeat = seatprice; // Define price per seat

    // Fetch seat data from the server
    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const response = await fetch(`${BASE_URL}seatfetching.php`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ bustype, packageId })
                });

                const data = await response.json();
                if (data.success) {
                    setSeats(data.seats);
                } else {
                    setError(data.message || "Failed to fetch seats.");
                }
            } catch (err) {
                setError(err.message || "An error occurred while fetching seats.");
            } finally {
                setLoading(false);
            }
        };
     const fetchEntryPoints = async () => {
                 try {
                     const response = await fetch(`${BASE_URL}entrypointfetching.php`, {
                         method: "POST",
                         headers: { "Content-Type": "application/json" },
                         body: JSON.stringify({ packageId }),
                     });
     
                     const data = await response.json();
                     if (data.success) {
                         setEntryPoints(data.entrypoints || []);
                     } else {
                         setError(data.message || "Failed to fetch entry points.");
                     }
                 } catch (err) {
                     setError(err.message || "An error occurred while fetching entry points.");
                 }
             };
     
             if (bustype && packageId) {
                 fetchDetails();
                 fetchEntryPoints();
             } else {
                 setError("Invalid bus type or package ID");
                 setLoading(false);
             }
         }, [bustype, packageId]);
         const toggleSeat = (seat) => {
            if (!seat.available || seat.payment_status === "booked") return;
        
            if (totalGuests <= 0) {
                alert("Please provide room details before selecting seats.");
                return;
            }
        
            if (selectedSeats.some((s) => s.id === seat.id)) {
                // Deselect the seat
                setSelectedSeats(selectedSeats.filter((s) => s.id !== seat.id));
            } else {
                if (selectedSeats.length < totalGuests) {
                    // Allow selection if the limit is not reached
                    setSelectedSeats([...selectedSeats, { id: seat.id, name: seat.seat_name }]);
                } else {
                    alert(`You can only select up to ${totalGuests} seats.`);
                }
            }
        };
        
    

    const getTotalPrice = () =>  pricePerSeat;

    // Helper function to organize seats in rows
    const organizeSeats = (seats) => {
        const rows = [];
        const firstRow = seats.slice(0, 3); // First row has only "R" seats
        const remainingSeats = seats.slice(3); // Remaining seats start from row 2

        for (let i = 0; i < remainingSeats.length; i += 5) {
            rows.push({
                left: remainingSeats.slice(i, i + 2), // Left seats (L)
                right: remainingSeats.slice(i + 2, i + 5) // Right seats (R)
            });
        }

        return { firstRow, rows };
    };

    const { firstRow, rows } = organizeSeats(seats);

    if (loading) return <div>Loading seats...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div>
            <Navbar />
            <NavLink to="/backbutton" className="back-button">
                ←
            </NavLink>
            
             <RoomBooking onApply={(guests, adults, children, rooms) => {
                setTotalGuests(guests);
                setAdults(adults);
                setChildren(children);
                setRooms(rooms)
            }} />
            <div className="seatbooking02-container">
            <div className="entry-point-container">
                    <label htmlFor="entry-point">Choose Entry Point:</label>
                    <select
                        id="entry-point"
                        className="entrypoint-select"
                        value={selectedEntryPoint}
                        onChange={(e) => setSelectedEntryPoint(e.target.value)}
                        
                    >
                        <option value="" disabled>
                            Select Entry Point
                        </option>
                        {entryPoints.map((entry) => (
                            <option key={entry.id} value={entry.id}>
                                {entry.entryplace}
                            </option>
                        ))}
                    </select>
                </div>
                
                <div className="d-flex align-items-center mb-3">
                    <h2 className="ms-2">Select Your Seat</h2>
                </div>
                <div className="seatbooking02-bus-layout">
                    <div className="seatbooking02-drive">
                        Driver
                        <img src={steering} alt="Steering Wheel" className="seatbooking02-steering-icon" />
                    </div>
                    <div className="seatbooking02-seats-grid">
                        {/* Render the first row */}
                        <div className="seatbooking02-seat-row">
                            <div className="seatbooking02-seat empty"></div>
                            <div className="seatbooking02-seat empty"></div>
                            <div className="seatbooking02-seat walking-space"></div>
                            {firstRow.map((seat) => (
                                <div
                                    key={seat.id}
                                    className={`seatbooking02-seat ${seat.available ? "" : "booked"} ${selectedSeats.some((s) => s.id === seat.id) ? "selected" : ""}`}
                                    onClick={() => toggleSeat(seat)}
                                >
                                    <img src={seatIcon} alt="Seat Icon" width="30" />
                                    {seat.available && <div className="seatbooking02-tooltip">Seat {seat.seat_name}</div>}
                                </div>
                            ))}
                        </div>

                        {/* Render remaining rows */}
                        {rows.map((row, rowIndex) => (
                            <div key={rowIndex} className="seatbooking02-seat-row">
                                {/* Render Left Side (L) */}
                                {row.left.map((seat) => (
                                    <div
                                        key={seat.id}
                                        className={`seatbooking02-seat ${seat.available ? "" : "booked"} ${selectedSeats.some((s) => s.id === seat.id) ? "selected" : ""}`}
                                        onClick={() => toggleSeat(seat)}
                                    >
                                        <img src={seatIcon} alt="Seat Icon" width="30" />
                                        {seat.available && <div className="seatbooking02-tooltip">Seat {seat.seat_name}</div>}
                                    </div>
                                ))}

                                {/* Walking Space */}
                                <div className="seatbooking02-seat walking-space"></div>

                                {/* Render Right Side (R) */}
                                {row.right.map((seat) => (
                                    <div
                                        key={seat.id}
                                        className={`seatbooking02-seat ${seat.available ? "" : "booked"} ${selectedSeats.some((s) => s.id === seat.id) ? "selected" : ""}`}
                                        onClick={() => toggleSeat(seat)}
                                    >
                                        <img src={seatIcon} alt="Seat Icon" width="30" />
                                        {seat.available && <div className="seatbooking02-tooltip">Seat {seat.seat_name}</div>}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="status-indicators">
                    <div className="indicator booked-indicator">
                        <div className="indicator-box"></div>
                        <span>Booked</span>
                    </div>
                    <div className="indicator available-indicator">
                        <div className="indicator-box"></div>
                        <span>Available</span>
                    </div>
                    <div className="indicator reserved-indicator">
                        <div className="indicator-box"></div>
                        <span>Reserved for Ladies</span>
                    </div>
                </div>

              <div className="total-price">
                                 <h3>Total Advance Amount: ₹{getTotalPrice()}</h3>
                                 {selectedSeats.length > 0 ? (
                                     selectedEntryPoint ? (
                                         <NavLink
                                             to="/billing"
                                             state={{
                                                 selectedSeats,
                                                 totalAmount: getTotalPrice(),
                                                 packageId,
                                                 bustype,
                                                 selectedEntryPoint,
                                                 adults,
                                                 children,
                                                 rooms,
                                             }}
                                             className="billing-link"
                                         >
                                             Proceed to Billing
                                         </NavLink>
                                     ) : (
                                         <p className="error-message" style={{ color: "red" }}>
                                             Please select an entry point to proceed to billing.
                                         </p>
                                     )
                                 ) : (
                                     <p className="error-message" style={{ color: "red" }}>
                                         Please select at least one seat to proceed.
                                     </p>
                                 )}
                             </div>
            </div>
            <Contact />
        </div>
    );
};

export default Seatbooking02;

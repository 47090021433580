import React, { useEffect, useState } from 'react';
import '../../css/Adminviewpackages.css';
import axios from 'axios';
import { BASE_URL } from "../../constants/constant";
import TopBar from './Topbar';
import Sidebar from '../../sidebar/sidebar';
import { BASE_URL_ADMIN } from "../../constants/constant";


function Adminviewpackage() {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    axios.get(`${BASE_URL}viewpackage.php`)
      .then(response => {
        if (response.data.success) {
          setPackages(response.data.data);
        } else {
          console.error("Failed to fetch packages:", response.data.message);
        }
      })
      .catch(error => {
        console.error("Error fetching packages:", error);
      });
  }, []);

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    return date.toLocaleDateString("en-GB", {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }) + 
    ` at ` + 
    date.toLocaleTimeString("en-GB", {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  
  const handlepackagestatus = (pkg) => {
    const requestData = {
      package_id: pkg.id,
      package_status: "deactive",
    };

    axios
      .post(`${BASE_URL_ADMIN}tourpackagedeactivation.php`, requestData)
      .then((response) => {
        if (response.data.success) {
          window.location.reload(); // Reload the current page
      
          
        } else {
          alert("Failed to update  status: " + response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        alert("An error occurred while updating  status.");
      });
  };

  return (

    <div className="dashboard-container">
    <div className="topbar-container">
      <TopBar />
    </div>
    <div className="main-content">
      <Sidebar />
      <div className="content-area">
        <h1 className="adminview_packages-h1">Packages</h1>
        <div className="adminview_packages-section">
          {packages.length > 0 ? (
            <div className="adminview_packages-list">
              {packages.map((pkg, index) => (
                <div className="adminview_packages" key={index}>
                  <img 
                    src={pkg.package_image} 
                    alt={pkg.Packagename} 
                    className="adminview_imgs" 
                  />
                  <h3 className="adminview_packages-name">{pkg.Packagename}</h3>
                  <p className="adminview_packagedetails"><b>Source:</b> {pkg.source}</p>
                  <p className="adminview_packagedetails"><b>Destination:</b> {pkg.destination}</p>
                  <p className="adminview_packagedetails"><b>Departure:</b> {formatDateTime(pkg.departure_time)}</p>
                  <p className="adminview_packagedetails"><b>Arrival:</b> {formatDateTime(pkg.arrival_time)}</p>
                  <p className="adminview_packagedetails"><b>Advanve Payment :</b> ${pkg.advanve_payment}</p>
                  <p className="adminview_packagedetails"><b>Total Tour price :</b> ${pkg.package_price}</p>
                  <p className="adminview_packagedetails"><b>Created by:</b> {pkg.creator_email}</p>
                  <p className="adminview_packagedetails">
                  <b>Entry Points:</b>
                  <ul>
                    {pkg.entry_points.split(',').map((point, idx) => (
                      <li key={idx} className="adminview_entrypoint">{point.trim()}</li>
                    ))}
                  </ul>
                </p>


                  <p className="adminview_packagedetails"><b>Bus Layout:</b> {pkg.bus_layout}</p>
                  <p className="adminview_packagedetails"><b>package id:</b> {pkg.id}</p>
                  <button className="booking-details-button"
                            onClick={() => handlepackagestatus(pkg)}
                          >
                            Package Deactivate
                          </button>
                </div>
              ))}
            </div>
          ) : (
            <p style={{ textAlign: 'center', fontSize: '18px', color: '#666' }}>
              No packages available.
            </p>
          )}
        </div>
      </div>
    </div>
  </div>
  );
}

export default Adminviewpackage;

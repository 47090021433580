import React from "react";
import '../../css/contact.css';
import logo02 from '../../images/New folder (4)/logo/logo02.png';
import { FaEnvelope, FaPhone, FaFacebook, FaInstagram, FaYoutube, FaWhatsapp, FaCcVisa, FaPaypal, FaCreditCard, FaApplePay, FaAmazonPay } from 'react-icons/fa';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { SiPhonepe } from "react-icons/si";
import { Link } from "react-router-dom";


function Footer() {
    return (
        <footer className="footer">
            <img src={logo02} alt="logo02" className="footer-logo" />
            <div className="container footer-section">
                <div className="footer-col">
                    <h4>Newsletter Sign In</h4>
                    <p>Receive our latest updates about products and promotions</p>
                    <input type="email" placeholder="Email Address" />
                    <button>Subscribe</button>
                </div>
                <div className="footer-col">
                    <h4>Contact Us</h4>
                    <p><LocationOnIcon /> Letsenjoy.com, Karodan Buildings, Muttichira, Kottayam - 686613</p>
                    <p><FaEnvelope /> info@letsenjoy.com</p>
                    <p><FaPhone /> +915933941289</p>
                    <div className="footer-social-icons">
                        <FaFacebook />
                        <FaInstagram />
                        <FaYoutube />
                        <FaWhatsapp />
                    </div>
                </div>
                <div className="footer-col">
                    <h4>Information</h4>
                    {/* <Link to=" ">About Us</Link><br />
                    <Link to=" ">Bus Type</Link><br />
                    <Link to=" ">Customer Service</Link><br /> */}
                    <Link to="/refund-policy">Refund Policy</Link><br />
                    <Link to="/privacy-policy">Privacy Policy</Link><br />
                    <Link to="/terms-and-conditions">Terms & Conditions</Link>
                </div>
                <div className="footer-col">
                    <h4>Payment Methods</h4>
                    <div className="footer-payment-icons">
                        <FaCcVisa />
                        <FaAmazonPay />
                        <FaPaypal />
                        <FaApplePay />
                        <FaCreditCard />
                        <SiPhonepe /> 
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

import React from "react";
import '../css/UserDetails.css';
import UserDetailsForm from "./UserDetailsForm";

function UserDetails() {
    return (
      <div id="UserDetails">
        
          {/* <h2 className="UserDetails-heading2">YOUR</h2> */}
          <UserDetailsForm className="UserDetails-component"/>
          </div>
     
    );
  }
  
  export default UserDetails;
  





import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(window.innerWidth >= 768);

    const toggleSidebar = () => {
        setIsOpen((prev) => !prev);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsOpen(window.innerWidth >= 768); // Always open on larger screens
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            {/* Sidebar */}
            <div
                className="bg-dark text-light position-fixed"
                style={{
                    width: '230px',
                    height: '100%',
                   
                    left: isOpen ? '0' : '-240px', // Adjust for open/close
                    transition: 'left 0.3s ease-in-out', // Smooth transition
                    zIndex: 1000,
                    overflowY: 'auto',
                    
                }}
            >
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <Link className="nav-link" to="/admindashboard">
                            Tour Package Booking Details
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/adminviewfamilypackage_booking">
                            Family Tour  Package Booking Details
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/packageadding">
                           Add tour Package
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/familypackage">
                            <i></i> Add family tour package
                        </Link>
                    </li>
                    
                    <li className="nav-item">
                    <Link className="nav-link " to="/adminviewpackage">

                            <i className=""></i> Active tour Packages
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/adminviewfamilypackage">
                           Active Family Packages
                        </Link>
                    </li>
                </ul>
            </div>

            {/* Toggle Button (Small Screens Only) */}
            <button
                onClick={toggleSidebar}
                className="btn btn-primary d-md-none position-fixed"
                style={{
                    top: '20px',
                    right: '20px',
                    zIndex: 1100,
                    maxWidth: '40px',
                }}
            >
                {isOpen ? '✖' : '☰'}
            </button>
        </div>
    );
};

export default Sidebar;

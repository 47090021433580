import React, { useState, useEffect } from "react";
import "../css/packageAdding.css";
import TopBar from "../Navbar/Pages/Topbar";
import Sidebar from "../sidebar/sidebar";
import axios from "axios";
import { BASE_URL_ADMIN } from "../constants/constant";
import { validateImageFile } from "../validation";

function PackageAdding() {
    const [formData, setFormData] = useState({
        packageName: "",
        seatprice: "",
        source: "",
        destination: "",
        departure: "",
        arrival: "",
        advancepayment: "",
        packageprice: "",
        bustype: "",
        packageimage: null,
        entryPoints: [""], // Array to hold entry points
    });

    const [busTypes, setBusTypes] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
    const [adminId, setAdminId] = useState(null);

    useEffect(() => {
        const storedAdminId = sessionStorage.getItem("admin_id");
        setAdminId(storedAdminId);
    }, []);

    useEffect(() => {
        axios
            .post(`${BASE_URL_ADMIN}bus_type_fetching.php`)
            .then((response) => {
                if (response.data && response.data.bus_types) {
                    setBusTypes(response.data.bus_types);
                }
            })
            .catch((error) => console.error("Error fetching bus types:", error));

        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth >= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleSidebarToggle = (isOpen) => setIsSidebarOpen(isOpen);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && !validateImageFile(file)) {
            alert("Please upload a valid image file (JPEG, JPG, PNG, GIF).");
            e.target.value = "";
            return;
        }
        setFormData((prevData) => ({ ...prevData, packageimage: file }));
    };

    const handleEntryPointChange = (index, value) => {
        const updatedEntryPoints = [...formData.entryPoints];
        updatedEntryPoints[index] = value;
        setFormData((prevData) => ({ ...prevData, entryPoints: updatedEntryPoints }));
    };

    const addEntryPoint = () => {
        setFormData((prevData) => ({
            ...prevData,
            entryPoints: [...prevData.entryPoints, ""],
        }));
    };

    const removeEntryPoint = (index) => {
        const updatedEntryPoints = formData.entryPoints.filter((_, i) => i !== index);
        setFormData((prevData) => ({ ...prevData, entryPoints: updatedEntryPoints }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!adminId) {
            alert("Admin ID is required to submit the form!");
            return;
        }
        const formDataToSend = new FormData();
        Object.keys(formData).forEach((key) => {
            if (key === "entryPoints") {
                formDataToSend.append(key, JSON.stringify(formData[key]));
            } else {
                formDataToSend.append(key, formData[key]);
            }
        });
        formDataToSend.append("created_by", adminId);

        axios
            .post(`${BASE_URL_ADMIN}packageadding.php`, formDataToSend, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((response) => {
                if (response.data.success) {
                    alert("Package added successfully!");
                    setFormData({
                        packageName: "",
                        seatprice: "",
                        source: "",
                        destination: "",
                        departure: "",
                        arrival: "",
                        advancepayment: "",
                        packageprice: "",
                        bustype: "",
                        packageimage: null,
                        entryPoints: [""],
                    });
                } else {
                    alert(`Error: ${response.data.message}`);
                }
            })
            .catch((error) => {
                console.error("Error adding package:", error);
                alert("An error occurred while adding the package.");
            });
    };

    return (
        <div className="container-fluid">
            <TopBar />
            <div className="row">
                <Sidebar onToggle={handleSidebarToggle} />
                <div className={`col ${!isSidebarOpen ? "col-12" : "col-md-9 offset-md-3"} p-4`}>
                    <h2 className="text-center mb-4">Add a Package</h2>
                    <form onSubmit={handleSubmit} className="package-form">
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label htmlFor="packageName" className="form-label">Package Name</label>
                                <input
                                    type="text"
                                    id="packageName"
                                    name="packageName"
                                    className="form-control"
                                    value={formData.packageName}
                                    onChange={handleChange}
                                    placeholder="Enter package name"
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="source" className="form-label">Source</label>
                                <input
                                    type="text"
                                    id="source"
                                    name="source"
                                    className="form-control"
                                    value={formData.source}
                                    onChange={handleChange}
                                    placeholder="Enter source"
                                />
                            </div>
                            
                        </div>
                        <div className="row mb-3">
                           
                            <div className="col-md-6">
                                <label htmlFor="destination" className="form-label">Destination</label>
                                <input
                                    type="text"
                                    id="destination"
                                    name="destination"
                                    className="form-control"
                                    value={formData.destination}
                                    onChange={handleChange}
                                    placeholder="Enter destination"
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Entry Points</label>
                            {formData.entryPoints.map((entryPoint, index) => (
                                <div key={index} className="d-flex mb-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={entryPoint}
                                        onChange={(e) => handleEntryPointChange(index, e.target.value)}
                                        placeholder={`Entry Point ${index + 1}`}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-danger ms-2"
                                        onClick={() => removeEntryPoint(index)}
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))}
                            <button
                                type="button"
                                className="btn btn-secondary entry_points"
                                onClick={addEntryPoint}
                            >
                                + Add Entry Point
                            </button>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label htmlFor="departure" className="form-label">Departure Time</label>
                                <input
                                    type="datetime-local"
                                    id="departure"
                                    name="departure"
                                    className="form-control"
                                    value={formData.departure}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="arrival" className="form-label">Arrival Time</label>
                                <input
                                    type="datetime-local"
                                    id="arrival"
                                    name="arrival"
                                    className="form-control"
                                    value={formData.arrival}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label htmlFor="advancepayment" className="form-label">Advance Payment</label>
                                <input
                                    type="text"
                                    id="advancepayment"
                                    name="advancepayment"
                                    className="form-control"
                                    value={formData.advancepayment}
                                    onChange={handleChange}
                                    placeholder="Enter advance payment price"
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="packageprice" className="form-label">Package Price</label>
                                <input
                                    type="text"
                                    id="packageprice"
                                    name="packageprice"
                                    className="form-control"
                                    value={formData.packageprice}
                                    onChange={handleChange}
                                    placeholder="Enter package price"
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="seatprice" className="form-label">Seat Price</label>
                                <input
                                    type="text"
                                    id="seatprice"
                                    name="seatprice"
                                    className="form-control"
                                    value={formData.seatprice}
                                    onChange={handleChange}
                                    placeholder="Enter"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label htmlFor="bustype" className="form-label">Bus Type</label>
                                <select
                                    id="bustype"
                                    name="bustype"
                                    className="form-select"
                                    value={formData.bustype}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled>Select a bus type</option>
                                    {busTypes.map((bus) => (
                                        <option key={bus.id} value={bus.id}>{bus.layout}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="packageimage" className="form-label">Upload Image</label>
                                <input
                                    type="file"
                                    id="packageimage"
                                    name="packageimage"
                                    className="form-control"
                                    onChange={handleFileChange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default PackageAdding;

import React, { useState, useEffect } from "react";
import "../../css/Destinations.css";
import axios from "axios";
import { BASE_URL } from "../../constants/constant";
import { NavLink } from "react-router-dom";

function FamilyPackageview({ searchQuery }) {  // Receive searchQuery as a prop
  const [destinations, setDestinations] = useState([]);
  const [filteredDestinations, setFilteredDestinations] = useState([]);

  useEffect(() => {
    // Fetch package data from PHP API
    axios
      .get(`${BASE_URL}viewfamilypackage_user.php`)
      .then((response) => {
        if (response.data.success) {
          setDestinations(response.data.data);
          setFilteredDestinations(response.data.data);  // Initialize with all packages
        } else {
          console.error("Failed to fetch packages:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching packages:", error);
      });
  }, []);

  // Filter destinations whenever searchQuery or destinations change
  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredDestinations(destinations);  // Show all if search is empty
    } else {
      const filtered = destinations.filter(pkg =>
        pkg.packagename.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredDestinations(filtered);
    }
  }, [searchQuery, destinations]);

  return (
    <div className="destination-maindiv">
      <h2 className="destination-h2">
        <b>Family Tour Packages</b>
      </h2>

      <div className="destination-section">
        <div className="destination-list">
          {filteredDestinations.length > 0 ? (
            filteredDestinations.map((pkg, index) => (
              <div className="destination" key={index}>
                <img
                  src={pkg.package_image}
                  alt={pkg.packagename}
                  className="imgs"
                />
                <p className="destination-name">{pkg.packagename}</p>
                <p className="destination-name">Adult Per Head Price: {pkg.adult_perheadprice}</p>
                <p className="destination-name">Child Per Head Price: {pkg.child_perheadprice}</p>

                <NavLink
                  to="/loginpage"
                  state={{ packageId: pkg.id }}
                  className="destination-navlink"
                >
                  Book Now
                </NavLink>
              </div>
            ))
          ) : (
            <p>No packages related to this place are available.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default FamilyPackageview;

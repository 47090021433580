import React from "react";
import seats from '../../images/New folder (4)/07.png';
import '../../css/About.css';  // Keeping this for essential CSS
import Navbar from "../Navbar";
import img8 from '../../images/New folder (4)/Awomenandmenstandingfrontofthebusandthecouplesarecarryingcameraandmapsbackpackstheirlooklinetourist.png';
import img9 from '../../images/New folder (4)/Awomenandmenstandingfrontofthebusandthecouplesarecarryingcameraandmapsbackpackstheirlooklinetourist.png';
import img10 from '../../images/New folder (4)/Four peoples are talking inside the bus and their  smiling..png';
import image1 from '../../images/New folder (4)/08.png';
import Contact from "./Contact";

function About() {
    return (
        <div className=" about-container">
            <Navbar />

            {/* Main image section with overlay text */}
            <div className="position-relative text-center">
                <img src={seats} alt="seats" className="img-fluid w-100" />
                <div className="position-absolute top-50 start-50 translate-middle text-white">
                    <h1 className="display-4 fw-bold text-shadow" style={{ color: '#fff' }}>About Us</h1>
                    <p className="lead">Be Comfortable while you travel with our reliable bus crew.</p>
                </div>
            </div>

            {/* Who We Are section */}
        <div className="text-center my-5">
            <h2>Who We Are</h2>
            <p className="text-primary h4">Let’s Enjoy — Your Travel, Our Passion</p>
            <p className="text-muted">
                At Let's Enjoy Tour Planners, we believe that every journey should be unforgettable. We are a team of passionate travel enthusiasts dedicated to crafting personalized travel experiences that match your dreams, budget, and style. 
                Whether it's a relaxing beach getaway, a thrilling adventure, or a cultural exploration, we take care of every detail—so you can focus on making memories. 
                With years of expertise, trusted partnerships, and a deep love for discovery, we’re here to turn your travel ideas into incredible realities. Let’s plan your next escape together!
            </p>
        </div>


            {/* Image Gallery Section */}
            <div className="row text-center my-4">
                <div className="col-md-4 mb-3">
                    <img src={img8} alt="Bus interior" className="img-fluid rounded shadow-sm" />
                </div>
                <div className="col-md-4 mb-3">
                    <img src={img9} alt="Tourists" className="img-fluid rounded shadow-sm" />
                </div>
                <div className="col-md-4 mb-3">
                    <img src={img10} alt="People talking" className="img-fluid rounded shadow-sm" />
                </div>
            </div>

            {/* Additional Content Image */}
            <div className="text-center my-4">
                <img src={image1} alt="About Us" className="img-fluid rounded shadow" />
            </div>

            <Contact />
        </div>
    );
}

export default About;

import React from "react";
import '../css/login.css';
import LoginForm from "./LoginForm";

function Login() {
    return (
      <div id="login">
       
          {/* <h2 class="login-heading2">TRAVEL</h2> */}
          <LoginForm className="login-component"/>
          </div>
     
    );
  }
  
  export default Login;
  






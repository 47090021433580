// Validate First Name and Last Name (only letters)
export const validateName = (name) => {
  const regex = /^[A-Za-z]+$/;
  return regex.test(name);
};

// Validate Password (minimum 8 characters, with at least one special character)
export const validatePassword = (password) => {
  const regex = /^(?=.*[!@#$%^&*])[A-Za-z\d@$!%*?&]{8,}$/;
  return regex.test(password);
};

// Validate Phone Number (only digits)
export const validatePhoneNumber = (phoneNumber) => {
  const regex = /^[0-9]{10}$/; // 10-digit phone number
  return regex.test(phoneNumber);
};

// Validate Email (valid email format)
export const validateEmail = (email) => {
  const regex = /\S+@\S+\.\S+/; // Proper email format regex
  return regex.test(email);
};

// Validate Image File (only specific formats)
export const validateImageFile = (file) => {
  const allowedTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
  return file && allowedTypes.includes(file.type);
};

// Validate that input contains only numbers
export const validateOnlyNumbers = (input) => {
  const regex = /^[0-9]*$/; // Matches only digits or an empty string (for backspace)
  return regex.test(input);
};


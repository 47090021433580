import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import seatIcon from "../images/New folder (4)/bus/seat.png";
import steering from "../images/New folder (4)/bus/stearing wheel.png";
import "../css/seatbooking.css";
import { BASE_URL } from "../../src/constants/constant";
import Contact from "../../src/Navbar/Pages/Contact";
import RoomBooking from "../Navbar/Pages/roombooking";

const SeatBooking = () => {
    const location = useLocation();
    const { packageId, bustype, seatprice,packageprice } = location.state || {};

    const [seats, setSeats] = useState([]);
    const [selectedSeats, setSelectedSeats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [packageDetails, setPackageDetails] = useState(null);
    const [totalGuests, setTotalGuests] = useState(0);
    const [maxSeats, setMaxSeats] = useState(0);
    const [entryPoints, setEntryPoints] = useState([]);
    const [selectedEntryPoint, setSelectedEntryPoint] = useState("");
    const [adults, setAdults] = useState(0);
    const [children, setChildren] = useState(0);
    const [rooms, setRooms] = useState(0);


    const pricePerSeat = seatprice;

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${BASE_URL}seatfetching.php`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ bustype, packageId }),
                });

                const data = await response.json();
                if (data.success) {
                    setSeats(
                        data.seats.map((seat) => ({
                            id: seat.id,
                            name: seat.seat_name,
                            available: seat.available,
                            payment_status: seat.payment_status || "available",
                        }))
                    );
                    setPackageDetails(data.package);
                    setMaxSeats(data.package.max_guests);
                } else {
                    setError(data.message || "Failed to fetch seats and package.");
                }
            } catch (err) {
                setError(err.message || "An error occurred while fetching seats and package.");
            } finally {
                setLoading(false);
            }
        };

        const fetchEntryPoints = async () => {
            try {
                const response = await fetch(`${BASE_URL}entrypointfetching.php`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ packageId }),
                });

                const data = await response.json();
                if (data.success) {
                    setEntryPoints(data.entrypoints || []);
                } else {
                    setError(data.message || "Failed to fetch entry points.");
                }
            } catch (err) {
                setError(err.message || "An error occurred while fetching entry points.");
            }
        };

        if (bustype && packageId) {
            fetchDetails();
            fetchEntryPoints();
        } else {
            setError("Invalid bus type or package ID");
            setLoading(false);
        }
    }, [bustype, packageId]);

    const toggleSeat = (seat) => {
        if (!seat.available || seat.payment_status === "booked" || seat.payment_status === "locked") return;

        const totalSelectedSeats = selectedSeats.length + (selectedSeats.some((s) => s.id === seat.id) ? 0 : 1);

        if (totalSelectedSeats > totalGuests) {
            alert(`First set the total number of members then book seats`);
            return;
        }

        const updatedSelectedSeats = selectedSeats.some((s) => s.id === seat.id)
            ? selectedSeats.filter((s) => s.id !== seat.id)
            : [...selectedSeats, { id: seat.id, name: seat.name }];
        setSelectedSeats(updatedSelectedSeats);
    };

    const getTotalPrice = () =>  pricePerSeat * selectedSeats.length;

    const totalpackagePrice = () => packageprice  * selectedSeats.length;

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <Navbar />
            <NavLink to="/backbutton" className="back-button">←</NavLink>
            <RoomBooking onApply={(guests, adults, children, rooms) => {
                setTotalGuests(guests);
                setAdults(adults);
                setChildren(children);
                setRooms(rooms)
            }} />
            <div className="seat-booking-container">
                <div className="entry-point-container">
                    <label htmlFor="entry-point">Choose Entry Point:</label>
                    <select
                        id="entry-point"
                        className="entrypoint-select"
                        value={selectedEntryPoint}
                        onChange={(e) => setSelectedEntryPoint(e.target.value)}
                    >
                        <option value="" disabled>Select Entry Point</option>
                        {entryPoints.map((entry) => (
                            <option key={entry.id} value={entry.id}>{entry.entryplace}</option>
                        ))}
                    </select>
                </div>

                <div className="bus-layout">
                    <div className="drive">
                        Driver
                        <img src={steering} alt="Steering Wheel" className="steering-icon" />
                    </div>
                    <div className="seats-grid">
                        {seats.map((seat) => (
                            <div
                                key={seat.id}
                                className={`seat 
                                    ${seat.payment_status === "booked" || seat.payment_status === "locked" ? "booked" : ""} 
                                    ${selectedSeats.some((s) => s.id === seat.id) ? "selected" : ""} 
                                    ${[35, 36].includes(seat.id) ? "reserved-ladies" : ""}`}
                                onClick={() => toggleSeat(seat)}
                            >
                                <img src={seatIcon} alt="Seat Icon" width="30" />
                                <div className="tooltip">Seat {seat.name}</div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="total-price">
                    <h3>Total Advance Amount: ₹{getTotalPrice()}</h3>
                    <h3>Total pacakge Amount: ₹{totalpackagePrice()}</h3>

                    {selectedSeats.length > 0 ? (
                        selectedEntryPoint ? (
                            <NavLink
                                to="/billing"
                                state={{
                                    selectedSeats,
                                    totalAmount: getTotalPrice(),
                                    packageId,
                                    bustype,
                                    selectedEntryPoint,
                                    adults,
                                    children,
                                    rooms,
                                }}
                                className="billing-link"
                            >
                                Proceed to Billing
                            </NavLink>
                        ) : (
                            <p className="error-message" style={{ color: "red" }}>
                                Please select an entry point to proceed to billing.
                            </p>
                        )
                    ) : (
                        <p className="error-message" style={{ color: "red" }}>
                            Please select at least one seat to proceed.
                        </p>
                    )}
                </div>

            </div>
            <Contact />
        </div>
    );
};

export default SeatBooking;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Contact from "../Navbar/Pages/Contact";
import "../css/billing.css";
import { BASE_URL } from "../../src/constants/constant";

const Billing = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { selectedSeats, totalAmount, packageId, bustype, selectedEntryPoint, adults, children, rooms } = location.state || {
        selectedSeats: [],
        totalAmount: 0,
        packageId: null,
        bustype: null,
        selectedEntryPoint: "",
        adults: 0,
        children: 0,
        rooms:0,
    };

    const [isLocked, setIsLocked] = useState(false);
    const [lockError, setLockError] = useState(null);
    const userId = sessionStorage.getItem("user_id");

    useEffect(() => {
    

        const lockSeats = async () => {
            if (selectedSeats.length === 0) {
                setLockError("No seats selected to lock.");
                return;
            }

            const seatIds = selectedSeats.map((seat) => seat.id);

            try {
                const response = await fetch(`${BASE_URL}lock_seat.php`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ seat_ids: seatIds, package_id: packageId, user_id: userId }),
                });

                const data = await response.json();

                if (data.success) {
                    setIsLocked(true);
                } else {
                    setLockError(data.message || "Failed to lock seats.");
                }
            } catch (error) {
                setLockError("An error occurred while locking seats.");
                console.error("Lock Seats Error:", error);
            }
        };

        lockSeats();

        return () => {
            const unlockSeats = async () => {
                if (selectedSeats.length === 0) return;

                const seatIds = selectedSeats.map((seat) => seat.id);

                try {
                    const response = await fetch(`${BASE_URL}unlock_seat.php`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ seat_ids: seatIds, package_id: packageId, user_id: userId }),
                    });

                    const data = await response.json();

                    if (!data.success) {
                        console.error("Failed to unlock seats:", data.message);
                    }
                } catch (error) {
                    console.error("Unlock Seats Error:", error);
                }
            };

            unlockSeats();
        };
    }, [selectedSeats, packageId, userId, adults, children, rooms]);

    const handleBooking = async () => {
        if (!isLocked) {
            alert("Seats are not locked yet. Please try again.");
            return;
        }
    
        if (selectedSeats.length === 0) {
            alert("Please select at least one seat.");
            return;
        }
    
        try {
            const seatIds = selectedSeats.map((seat) => seat.id);
    
            const bookingDetails = {
                user_id: userId,
                package_id: packageId,
                seat_id: seatIds,
                bus_id: bustype,
                payment_status: "booked",
                balance_payment:"pending",
                entry_point: selectedEntryPoint,
                adults,
                children,
                rooms,
               
            };
    
            const response = await fetch(`${BASE_URL}booking.php`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(bookingDetails),
            });
    
            // Check for success status before parsing
            if (!response.ok) {
                throw new Error("Failed to book seats.");
            }
    
            const data = await response.json();
    
            if (data.success) {
                alert("Booking added successfully!");
                navigate("/loginsubmit");
            } else {
                alert("Booking Error: " + data.message);
            }
        } catch (error) {
            console.error("Booking Error:", error);
            alert("An error occurred during booking. Please try again.");
        }
    };
    

    return (
        <div>
            <Navbar />
            <div className="billing-container">
                <h2 className="billing-h2">Billing Details</h2>
                {lockError ? (
                    <div className="error-message">Error: {lockError}</div>
                ) : (
                    <div>
                        <h3>Selected Seats:</h3>
                        <ul className="seatsul">
                            <li>
                                {selectedSeats.map((seat) => `Seat ${seat.name}`).join(", ")}
                            </li>
                        </ul>

                        <div className="guests-info">
                            <p>Total Adults: {adults}</p>
                            <p>Total Children: {children}</p>
                            <p>Total Rooms: {rooms}</p>
                        </div>
                        <h3 className="total-amount">Total Advance Payment: ₹{totalAmount}</h3>
                        
                      

                        <button className="make-payment-button" onClick={handleBooking} disabled={!isLocked}>
                            Make Payment
                        </button>
                    </div>
                )}
            </div>
            <Contact />
        </div>
    );
};

export default Billing;

import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { BASE_URL } from "../../constants/constant"; // Ensure this is correct
import '../../css/Usertickets.css';
import { Pdfgenerator_tourpackage } from "../../Navbar/Pages/Pdfgeneratorfor_familypackage";


const Familypackage_tickets = () => {
    const [userId, setUserId] = useState("");
    const [bookings, setBookings] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Retrieve the user_id from sessionStorage
        const storedUserId = sessionStorage.getItem("user_id");
        if (storedUserId) {
            setUserId(storedUserId);

            // Fetch user bookings from the API
            fetch(`${BASE_URL}familypackage_tickets.php`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ userId: storedUserId }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.error) {
                        setError(data.error);
                    } else {
                        setBookings(data); // Store the bookings in state
                    }
                })
                .catch((err) => {
                    console.error("Fetch error:", err);
                    setError("Failed to fetch booking details.");
                });
        } else {
            setError("User ID not found in session storage.");
        }
    }, []); // Dependency array is empty to only run on component mount

    return (
        <div>
            {error ? (
                <p className="user-profile-error-message">{error}</p>
            ) : (
                <div className="user-profile-booking-container">
                    <h2>Family Package Bookings </h2>
                    {bookings.length > 0 ? (
                        bookings.map((booking, index) => (
                            <div key={index} className="booking-card">
                                <div className="card-title">{booking.package_name}</div>
                                <div className="card-detail">
                                    <span>Trip Start Date:</span>
                                    <span>{new Date(booking.trip_start_date).toLocaleDateString()}</span>
                                </div>
                                <div className="card-detail">
                                    <span>Booking Timestamp:</span>
                                    <span>{new Date(booking.booking_timestamp).toLocaleString()}</span>
                                </div>
                                <div className="card-detail">
                                    <span>Destination:</span>
                                    <span>{booking.destination}</span>
                                </div>
                                <div className="card-detail">
                                    <span>Vehicle:</span>
                                    <span>{booking.vehicle_name} (Seats: {booking.seating_capacity})</span>
                                </div>
                                <div className="card-detail">
                                    <span>Adults:</span>
                                    <span>{booking.adults}</span>
                                </div>
                                <div className="card-detail">
                                    <span>Children:</span>
                                    <span>{booking.children}</span>
                                </div>
                                <div className="card-detail">
                                    <span>Rooms:</span>
                                    <span>{booking.rooms}</span>
                                </div>
                                <div className="card-detail">
                                    <span>Total Amount:</span>
                                    <span>₹{booking.total_amount.toFixed(2)}</span>
                                </div>
                                <div className="card-detail">
                                    <span>Advance Payment:</span>
                                    <span>₹{booking.advance_payment.toFixed(2)}</span>
                                </div>
                                <div className="card-detail">
                                    <span>Balance Payment:</span>
                                    <span>₹{booking.balance_payment.toFixed(2)}</span>
                                </div>
                                 <button
                                                  className="download-ticket-button"
                                                  onClick={() => Pdfgenerator_tourpackage(booking)}
                                                >
                                                  Download Ticket
                                                </button>
                            </div>
                        ))
                    ) : (
                        <p>No bookings found.</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default Familypackage_tickets;
